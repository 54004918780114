// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Data
import { useEffect, useState } from "react";
import WarehouseService from "services/warehouses";

import { TextField } from "@mui/material";
import { ptBR_DataGrid } from "utils/datagrid";
import DetalharEntradaAlmoxarifado from "./components/detaiInput";
import tableData from "./components/tableTransacoes";
import DetalharSaidaAlmoxarifado from "./components/detailOutput";

export default function AlmoxarifadoTransacoes() {
  const [loading, setLoading] = useState(false);
  const [showDetailState, setShowDetailState] = useState(false);

  const [warehouseId, setWarehouseId] = useState({
    id: "",
    nome: "",
  });
  const [warehouses, setWarehouses] = useState([]);

  const handleDetail = (row) => {
    setShowDetailState(row);
  };

  const {
    columns,
    rows,
    updatePagination,
    fetchData,
    sortBy,
    filterBy,
    pageObject,
  } = tableData(setLoading, warehouseId.id, handleDetail);

  useEffect(() => {
    const fetchWarehouse = async () => {
      const warehouses = await WarehouseService.list();
      setWarehouses(warehouses);
      warehouses &&
        setWarehouseId({
          id: warehouses?.data[0]?._id,
          nome: warehouses?.data[0]?.descricao,
        });
    };

    fetchWarehouse();
  }, []);

  return (
    <>
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white" mt={1}>
                    Estoque - Entradas e Saídas
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <TextField
                    select
                    label="Selecione o Depósito"
                    onChange={(e) =>
                      setWarehouseId({
                        id: e.target.value,
                        nome: e.target.options[e.target.selectedIndex].text,
                      })
                    }
                    value={warehouseId.id}
                    SelectProps={{
                      native: true,
                    }}
                    sx={{
                      width: "400px",
                      margin: "0 0 20px 20px",
                    }}
                  >
                    {warehouses?.data?.length > 0 ? (
                      warehouses?.data.map((option, k) => (
                        <option key={k} value={option._id}>
                          {option.descricao}
                        </option>
                      ))
                    ) : (
                      <option value={""}></option>
                    )}
                  </TextField>
                  <Card sx={{ padding: 2 }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={pageObject.totalRows}
                      pageSizeOptions={[50, 100]}
                      paginationModel={{
                        pageSize: pageObject.perPage,
                        page: pageObject.page,
                      }}
                      paginationMode="server"
                      onPaginationModelChange={updatePagination}
                      sortingMode="server"
                      onSortModelChange={sortBy}
                      filterMode="server"
                      onFilterModelChange={filterBy}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  </Card>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {showDetailState?.type === "input" && (
        <DetalharEntradaAlmoxarifado
          row={showDetailState}
          setShowDetailState={setShowDetailState}
        />
      )}
      {showDetailState?.type === "output" && (
        <DetalharSaidaAlmoxarifado
          row={showDetailState}
          setShowDetailState={setShowDetailState}
        />
      )}
    </>
  );
}
