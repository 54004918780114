import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import Service from "services/sales";
import ProductService from "services/products";
import {
  STATUS_PEDIDO,
  TIPO_FRETE,
  findStatusPedido,
  findTipoFrete,
  initialValues,
  yupSchema,
} from "./yupSchema";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// @mui icons
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

// forms
import MDButton from "components/MDButton";
import { format } from "utils/formatDate";
import LabelForm from "layouts/pedidos-compra/components/LabelForm";
import maskPhone from "utils/maskPhone";
import { commaToDot, dotToComma } from "utils/numberComma";
import Contas from "./contas";
import Itens from "./itens";

// forms alternativos
import AdicionarAgenciador from "layouts/agenciadores";
import AdicionarCliente from "layouts/clientes";
import AdicionarFuncionario from "layouts/funcionarios";
import AdicionarTransportadora from "layouts/transportadora";
import AdicionarProduto from "layouts/produtos";

//@mui/styles
import { makeStyles } from "@mui/styles";
import ItensLote from "./itensLote";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function AdicionarPedidoVenda({ setShowAddState, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);

  // forms alternativos
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [showAddProdutoLote, setShowAddProdutoLote] = useState(false);
  const [showAddCliente, setShowAddCliente] = useState(false);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [showAddTransportadora, setShowAddTransportadora] = useState(false);
  const [showAddAgenciador, setShowAddAgenciador] = useState(false);

  const [produtosLote, setProdutosLote] = useState([]);

  const refEditItem = useRef(null);
  const [currentItem, setCurrentItem] = useState(null);

  // tabs
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  // erro produto
  const [erroProduto, setErroProduto] = useState(false);

  const [childData, setChildData] = useState({});

  const classes = useStyles();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setSuccess(null);
      setErroProduto(false);

      try {
        if (values.produtos.length === 0) {
          setErroProduto(true);
          throw new Error("Erro ao salvar produto");
        }

        const request = {
          ...values,
          customer: values.customer?._id || values.customer,
          user: values.user?._id || values.user,
          shippingCompany:
            values.shippingCompany?._id || values.shippingCompany,
          agent: values.agent?._id || values.agent,
        };
        await Service.create(request);
        formik.resetForm();
        setSuccess(true);
        setChildData({});
        fetchData();
        setShowAddState(false);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  const getRelatedData = (related, id) => {
    return related?.find((item) => item._id === id);
  };

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        formik.setFieldValue(name, `${removeDots},00`);
      }
    }
  };

  const calculateTotalFinal = () => {
    const totalFrete =
      Number(commaToDot(formik.values.carregamento)) +
      Number(commaToDot(formik.values.valorFrete)) +
      Number(commaToDot(formik.values.valorIcms));

    const totalPagarFrete =
      totalFrete - Number(commaToDot(formik.values.adiantamentoFrete));

    formik.setFieldValue(
      "totalPagarFrete",
      isNaN(totalPagarFrete) ? 0 : dotToComma(totalPagarFrete)
    );

    /* total final */
    let totalFinal = totalValorProdutos();

    if (formik.values.tipoFrete === TIPO_FRETE.FOB) {
      totalFinal = totalFinal - totalPagarFrete;
    }

    formik.setFieldValue(
      "totalFinal",
      isNaN(totalFinal) ? 0 : dotToComma(totalFinal.toFixed(2))
    );
  };

  const totalValorProdutos = () => {
    let total = 0;
    let valorTotal = 0;

    formik.values.produtos?.forEach((produto) => {
      total += produto.valorUnitario * produto.qtd;
      valorTotal += produto.valorUnitario * produto.qtd;
    });

    formik.setFieldValue(
      "valorTotal",
      isNaN(valorTotal) ? 0 : dotToComma(valorTotal.toFixed(2))
    );

    return total;
  };

  useEffect(() => {
    calculateTotalFinal();
  }, [
    formik.values.valorFrete,
    formik.values.valorIcms,
    formik.values.carregamento,
    formik.values.produtos,
    formik.values.adiantamentoFrete,
    formik.values.tipoFrete,
  ]);

  useEffect(() => {
    const media =
      parseInt(formik.values.pesoLiquido) / parseInt(formik.values.qtdFrutas);
    formik.setFieldValue(
      "media",
      isNaN(media) || !Number.isFinite(media) ? 0 : dotToComma(media.toFixed(2))
    );
  }, [formik.values.pesoLiquido, formik.values.qtdFrutas]);

  useEffect(() => {
    const pesoLiquido =
      parseInt(formik.values.pesoBruto) - parseInt(formik.values.tara);
    formik.setFieldValue("pesoLiquido", isNaN(pesoLiquido) ? 0 : pesoLiquido);
  }, [formik.values.pesoBruto, formik.values.tara]);

  useEffect(() => {
    if (childData.shippingCompany) {
      formik.setFieldValue("telefone", childData.shippingCompany.telefone);
      formik.setFieldValue("caminhao", childData.shippingCompany.veiculo);
      formik.setFieldValue(
        "motorista",
        childData.shippingCompany.nomeMotorista
      );
    }
  }, [childData]);

  useEffect(() => {
    const calculaToneladas = Number(
      parseInt(formik.values.pesoLiquido) / 1000
    ).toFixed(2);
    const toneladas = calculaToneladas > 1 ? calculaToneladas : 1;
    const valorFrete =
      toneladas * Number(commaToDot(formik.values.precoTonelada));
    formik.setFieldValue("valorFrete", isNaN(valorFrete) ? 0 : valorFrete);
  }, [formik.values.pesoLiquido, formik.values.precoTonelada]);

  useEffect(() => {
    formik.setFieldValue("data", format(new Date(), "yyyy-MM-dd"));
    const dataDescarga = new Date();
    const dataDescargaMais3Dias = new Date(
      dataDescarga.setDate(dataDescarga.getDate() + 3)
    );
    formik.setFieldValue(
      "dataDescarga",
      format(dataDescargaMais3Dias, "yyyy-MM-dd")
    );

    formik.setFieldValue("status", Object.values(STATUS_PEDIDO)[0]);
  }, []);

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddProduto) setShowAddProduto(false);
        if (showAddCliente) setShowAddCliente(false);
        if (showAddFuncionario) setShowAddFuncionario(false);
        if (showAddTransportadora) setShowAddTransportadora(false);
        if (showAddAgenciador) setShowAddAgenciador(false);

        if (
          !showAddProduto &&
          !showAddCliente &&
          !showAddFuncionario &&
          !showAddTransportadora &&
          !showAddAgenciador
        ) {
          setShowAddState(false);
          fetchData();
        }
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [
    showAddProduto,
    showAddCliente,
    showAddFuncionario,
    showAddTransportadora,
    showAddAgenciador,
  ]);

  useEffect(() => {
    const loadBatchProducts = async () => {
      const { data: batches } = await ProductService.listBatches();
      setProdutosLote(batches);
    };

    loadBatchProducts();
  }, [showAddProdutoLote]);

  const handleAdicionarDoLote = (produto, qtd) => {
    // remove quantity from produtosLote

    const newProdutosLote = produtosLote.map((item) => {
      if (item._id === produto._id) {
        return {
          ...item,
          quantidade: item.quantidade - qtd,
        };
      }
      return item;
    });

    setProdutosLote(newProdutosLote);
    // if product already exists, just increment the quantity
    const productExists = formik.values?.produtos?.find(
      (item) => item?._id === produto?._id
    );

    if (productExists) {
      const produtos = formik.values?.produtos?.map((item) => {
        if (item?._id === produto?._id) {
          return {
            ...item,
            qtd: item?.qtd + qtd,
            qtdDiminuirEstoque: item?.qtdDiminuirEstoque
              ? item?.qtdDiminuirEstoque + qtd
              : qtd,
            lote: produto._id,
          };
        }

        return item;
      });

      formik.setFieldValue("produtos", produtos);
      setShowAddProduto(false);
      setShowAddProdutoLote(false);
    }

    if (!productExists) {
      const produtos = [
        ...formik.values?.produtos,
        {
          _id: produto.produto?._id,
          codigoInterno: produto.produto?.codigoInterno,
          qtd: qtd,
          produto: produto.produto?.descricao,
          valorUnitario: produto.produto?.precoVenda,
          unidadeMedida: produto.produto.unidade,
          qtdDiminuirEstoque: qtd,
          lote: produto._id,
        },
      ];

      formik.setFieldValue("produtos", produtos);

      setShowAddProduto(false);
      setShowAddProdutoLote(false);
    }

    calculateTotalFinal();
  };

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
        }}
        ref={refEditItem}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={loading ? 4 : 11} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  {loading ? "Carregando..." : "Cadastrar Pedido de Venda"}
                </MDTypography>

                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    mb: 2,
                    display: loading ? "none" : "",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      md={3}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Número do Pedido">
                        <Typography
                          style={{ fontWeight: "bold", color: "#2C441C" }}
                        >
                          {formik.values.numPedido}
                        </Typography>
                      </LabelForm>
                    </Grid>

                    <Grid item md={3} sm={6} mb={2} pr={1}>
                      <TextField
                        id="data"
                        name="data"
                        label="Data"
                        type="date"
                        value={formik.values.data}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        error={
                          formik.touched.data && Boolean(formik.errors.data)
                        }
                        helperText={formik.touched.data && formik.errors.data}
                        sx={{
                          width: "100%",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item md={3} sm={6} mb={2} pr={1}>
                      <TextField
                        id="status"
                        name="status"
                        label="Status"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        sx={{
                          width: "100%",
                        }}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option aria-label="Selecione" value="" />
                        {Object.values(STATUS_PEDIDO).map((opcao) => (
                          <option key={opcao} value={opcao}>
                            {findStatusPedido(opcao)}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      md={3}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Valor Total dos Produtos">
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(commaToDot(formik.values.valorTotal))}
                      </LabelForm>
                    </Grid>

                    <Grid item md={6} sm={6} mb={2} pr={1}>
                      <Alert
                        icon={false}
                        severity="success"
                        action={
                          <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            iconOnly
                            onClick={() => setShowAddCliente(true)}
                          >
                            <Icon fontSize="small">edit</Icon>
                          </MDButton>
                        }
                        sx={{
                          border: formik.errors.customer ? "1px solid red" : "",
                        }}
                      >
                        <b>Cliente: </b>
                        {childData.customer?.nomeFantasia ||
                          childData.customer?.razaoSocial}
                      </Alert>
                    </Grid>

                    <Grid item md={6} sm={6} mb={2} pr={1}>
                      <Alert
                        icon={false}
                        severity="success"
                        action={
                          <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            iconOnly
                            onClick={() => setShowAddFuncionario(true)}
                          >
                            <Icon fontSize="small">edit</Icon>
                          </MDButton>
                        }
                        sx={{
                          border: formik.errors.user ? "1px solid red" : "",
                        }}
                      >
                        <b>Vendedor: </b>
                        {childData.user?.name}
                      </Alert>
                    </Grid>

                    <Grid item md={6} sm={6} mb={2} pr={1}>
                      <Alert
                        icon={false}
                        severity="success"
                        action={
                          <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            iconOnly
                            onClick={() => setShowAddTransportadora(true)}
                          >
                            <Icon fontSize="small">edit</Icon>
                          </MDButton>
                        }
                        sx={{
                          border: formik.errors.shippingCompany
                            ? "1px solid red"
                            : "",
                        }}
                      >
                        <b>Transportadora: </b>
                        {childData.shippingCompany?.nomeFantasia ||
                          childData.shippingCompany?.razaoSocial}
                      </Alert>
                    </Grid>

                    <Grid item md={6} sm={6} mb={2} pr={1}>
                      <Alert
                        icon={false}
                        severity="success"
                        action={
                          <MDButton
                            variant="gradient"
                            color="info"
                            fullWidth
                            iconOnly
                            onClick={() => setShowAddAgenciador(true)}
                          >
                            <Icon fontSize="small">edit</Icon>
                          </MDButton>
                        }
                        sx={{
                          border: formik.errors.agent ? "1px solid red" : "",
                        }}
                      >
                        <b>Agenciador: </b>
                        {childData.agent?.nome}
                      </Alert>
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <TextField
                        id="pesoBruto"
                        name="pesoBruto"
                        label="Peso Bruto"
                        type="number"
                        value={formik.values.pesoBruto}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 99);
                        }}
                        error={
                          formik.touched.pesoBruto &&
                          Boolean(formik.errors.pesoBruto)
                        }
                        helperText={
                          formik.touched.pesoBruto && formik.errors.pesoBruto
                        }
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <TextField
                        id="tara"
                        name="tara"
                        label="Tara"
                        type="number"
                        value={formik.values.tara}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 99);
                        }}
                        error={
                          formik.touched.tara && Boolean(formik.errors.tara)
                        }
                        helperText={formik.touched.tara && formik.errors.tara}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Peso Líquido">
                        {formik.values.pesoLiquido}
                      </LabelForm>
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <TextField
                        id="qtdFrutas"
                        name="qtdFrutas"
                        label="Quantidade de Frutas"
                        type="number"
                        value={formik.values.qtdFrutas}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 99);
                        }}
                        error={
                          formik.touched.qtdFrutas &&
                          Boolean(formik.errors.qtdFrutas)
                        }
                        helperText={
                          formik.touched.qtdFrutas && formik.errors.qtdFrutas
                        }
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Média">{formik.values.media}</LabelForm>
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <CurrencyInput
                        id="precoTonelada"
                        name="precoTonelada"
                        label="Preço por Tonelada"
                        value={formik.values.precoTonelada}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        decimalsLimit={2}
                        onValueChange={changeCurrency}
                        onBlurCapture={addZero}
                        customInput={TextField}
                        error={
                          formik.touched.precoTonelada &&
                          Boolean(formik.errors.precoTonelada)
                        }
                        helperText={
                          formik.touched.precoTonelada &&
                          formik.errors.precoTonelada
                        }
                        sx={{
                          width: "100%",
                        }}
                        prefix="R$ "
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      />
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <TextField
                        id="dataDescarga"
                        name="dataDescarga"
                        label="Data de Descarga"
                        type="date"
                        value={formik.values.dataDescarga}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        error={
                          formik.touched.dataDescarga &&
                          Boolean(formik.errors.dataDescarga)
                        }
                        helperText={
                          formik.touched.dataDescarga &&
                          formik.errors.dataDescarga
                        }
                        sx={{
                          width: "100%",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Valor do Frete">
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(commaToDot(formik.values.valorFrete))}
                      </LabelForm>
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <CurrencyInput
                        id="valorIcms"
                        name="valorIcms"
                        label="Valor ICMS"
                        value={formik.values.valorIcms}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        decimalsLimit={2}
                        onValueChange={changeCurrency}
                        onBlurCapture={addZero}
                        customInput={TextField}
                        error={
                          formik.touched.valorIcms &&
                          Boolean(formik.errors.valorIcms)
                        }
                        helperText={
                          formik.touched.valorIcms && formik.errors.valorIcms
                        }
                        sx={{
                          width: "100%",
                        }}
                        prefix="R$ "
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      />
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <CurrencyInput
                        id="carregamento"
                        name="carregamento"
                        label="Carregamento"
                        value={formik.values.carregamento}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        decimalsLimit={2}
                        onValueChange={changeCurrency}
                        onBlurCapture={addZero}
                        customInput={TextField}
                        error={
                          formik.touched.carregamento &&
                          Boolean(formik.errors.carregamento)
                        }
                        helperText={
                          formik.touched.carregamento &&
                          formik.errors.carregamento
                        }
                        sx={{
                          width: "100%",
                        }}
                        prefix="R$ "
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      />
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <CurrencyInput
                        id="adiantamentoFrete"
                        name="adiantamentoFrete"
                        label="Adiantamento Frete"
                        value={formik.values.adiantamentoFrete}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        decimalsLimit={2}
                        onValueChange={changeCurrency}
                        onBlurCapture={addZero}
                        customInput={TextField}
                        error={
                          formik.touched.adiantamentoFrete &&
                          Boolean(formik.errors.adiantamentoFrete)
                        }
                        helperText={
                          formik.touched.adiantamentoFrete &&
                          formik.errors.adiantamentoFrete
                        }
                        sx={{
                          width: "100%",
                        }}
                        prefix="R$ "
                        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <Grid container>
                        <Grid item md={6} sm={12}>
                          <LabelForm label="Total à Pagar Frete">
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(
                              commaToDot(formik.values.totalPagarFrete)
                            )}
                          </LabelForm>
                        </Grid>

                        <Grid item md={6} sm={12}>
                          <CurrencyInput
                            id="valorDescarga"
                            name="valorDescarga"
                            label="Valor Descarga"
                            value={formik.values.valorDescarga}
                            onBlur={formik.handleBlur}
                            disabled={loading}
                            decimalsLimit={2}
                            onValueChange={changeCurrency}
                            onBlurCapture={addZero}
                            customInput={TextField}
                            error={
                              formik.touched.valorDescarga &&
                              Boolean(formik.errors.valorDescarga)
                            }
                            helperText={
                              formik.touched.valorDescarga &&
                              formik.errors.valorDescarga
                            }
                            sx={{
                              width: "100%",
                            }}
                            prefix="R$ "
                            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={4}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Motorista">
                        {formik.values.motorista}
                      </LabelForm>
                    </Grid>

                    <Grid item md={2} sm={6} mb={2} pr={1}>
                      <TextField
                        id="tipoFrete"
                        name="tipoFrete"
                        label="Tipo Frete"
                        value={formik.values.tipoFrete}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        error={
                          formik.touched.tipoFrete &&
                          Boolean(formik.errors.tipoFrete)
                        }
                        helperText={
                          formik.touched.tipoFrete && formik.errors.tipoFrete
                        }
                        sx={{
                          width: "100%",
                        }}
                        select
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option aria-label="Selecione" value="" />
                        {Object.values(TIPO_FRETE).map((opcao) => (
                          <option key={opcao} value={opcao}>
                            {findTipoFrete(opcao)}
                          </option>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Caminhão">
                        {formik.values.caminhao}
                      </LabelForm>
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Telefone">
                        {maskPhone(formik.values.telefone)}
                      </LabelForm>
                    </Grid>

                    <Grid
                      item
                      md={2}
                      sm={6}
                      mb={2}
                      pr={1}
                      sx={{ lineHeight: "14px" }}
                    >
                      <LabelForm label="Total Final à Pagar">
                        <Typography
                          style={{ fontWeight: "bold", color: "#2C441C" }}
                        >
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(commaToDot(formik.values.totalFinal))}
                        </Typography>
                      </LabelForm>
                    </Grid>

                    <Grid item md={12} sm={12} mb={2} pr={1}>
                      <TextField
                        type="text"
                        multiline
                        rows={4}
                        id="observacoes"
                        name="observacoes"
                        label="Observações"
                        value={formik.values.observacoes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={loading}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ mx: "auto" }}
                      mb={2}
                    >
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                        className={classes.tabs}
                      >
                        <Tab label="Itens do Pedido" icon={<InventoryIcon />} />
                        <Tab
                          label="Contas à Receber do Pedido"
                          icon={<LocalAtmIcon />}
                        />
                      </Tabs>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ mx: "auto" }}
                      mb={2}
                    >
                      {tabValue === 0 && (
                        <Itens
                          {...{
                            formik,
                            loading,
                          }}
                          refEditItem={refEditItem}
                          setShowAddProduto={setShowAddProduto}
                          setShowAddProdutoLote={setShowAddProdutoLote}
                          setCurrentItem={setCurrentItem}
                          currentItem={currentItem}
                          isVenda={true}
                        />
                      )}
                      {tabValue === 1 && (
                        <Contas
                          {...{ formik, loading }}
                          refEditItem={refEditItem}
                          cliente={formik.values.customer}
                          valorTotal={formik.values.totalFinal}
                          dataPedido={formik.values.data}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {formik.errors.submit && (
                  <Box>
                    <MDAlert color="error" mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        {formik.errors.submit}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}

                {erroProduto && (
                  <Box>
                    <MDAlert color="error" mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        Você precisa adicionar um produto para cadastrar o
                        pedido de venda.
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Pedido de venda cadastrado com sucesso!"
                          : "Ocorreu um erro ao cadastrar o pedido de venda."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent={true}
            />
          </MDBox>
        )}

        {showAddCliente && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarCliente
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, customer: value })
                );
                selectParent("customer", value?._id);
              }}
              setShowAddCliente={setShowAddCliente}
            />
          </MDBox>
        )}

        {showAddFuncionario && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, user: value })
                );
                selectParent("user", value?._id);
              }}
              setShowAddFuncionario={setShowAddFuncionario}
            />
          </MDBox>
        )}

        {showAddTransportadora && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarTransportadora
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, shippingCompany: value })
                );
                selectParent("shippingCompany", value?._id);
              }}
              setShowAddTransportadora={setShowAddTransportadora}
            />
          </MDBox>
        )}

        {showAddAgenciador && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarAgenciador
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, agent: value })
                );
                selectParent("agent", value?._id);
              }}
              setShowAddAgenciador={setShowAddAgenciador}
            />
          </MDBox>
        )}

        {showAddProdutoLote && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <ItensLote
              setShowAddProdutoLote={setShowAddProdutoLote}
              produtosLote={produtosLote}
              handleAdicionar={handleAdicionarDoLote}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
