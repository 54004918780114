import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import AccountService from "services/accounts";
import Service from "services/batchOfSettlements";
import { initialValues, yupSchema } from "./yupSchema";

// @mui icons
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DataTable from "examples/Tables/DataTable";
import AdicionarContaAPagar from "layouts/payables";
import AdicionarContaAReceber from "layouts/receivables";
import DetalharContaAPagar from "layouts/payables/components/detailData";
import DetalharContaAReceber from "layouts/receivables/components/detailData";
import CurrencyInput from "react-currency-input-field";
import { dotToComma } from "utils/numberComma";
import { commaToDot } from "utils/numberComma";
import { format } from "date-fns";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function AdicionarLoteBaixa({ setShowAddState, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [dataList, setDataList] = useState({
    accounts: [],
  });

  const [showAddPayable, setShowAddPayable] = useState(false);
  const [showAddReceivable, setShowAddReceivable] = useState(false);
  const [showDatailPayable, setShowDatailPayable] = useState(false);
  const [showDetailReceivable, setShowDetailReceivable] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const refAddItem = useRef(null);

  const [payables, setPayables] = useState([]);
  const [receivables, setReceivables] = useState([]);

  const [focusedDataPagamento, setFocusedDataPagamento] = useState(false);

  // tabs
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setShowAddPayable(false);
    setShowAddReceivable(false);
    setTabValue(newValue);
  };
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await Service.create(values);
        formik.resetForm();
        setPayables([]);
        setReceivables([]);
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  const scrollToTop = () => {
    refAddItem?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      const { data: accounts } = await AccountService.list();
      setDataList({ accounts });
    };

    fetchAccounts();

    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowAddState(false);
        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  useEffect(() => {
    if (currentItem) {
      if (showAddPayable) {
        if (!payables.find((item) => item._id === currentItem._id)) {
          formik.setFieldValue("payables", [
            ...formik.values.payables,
            {
              payable_id: currentItem._id,
              tipoDeBaixa: "T",
              valor: currentItem.valor,
              dataBaixa: format(new Date(), "yyyy-MM-dd"),
            },
          ]);
          setPayables([...payables, currentItem]);
        }
      } else if (showAddReceivable) {
        if (!receivables.find((item) => item._id === currentItem._id)) {
          formik.setFieldValue("receivables", [
            ...formik.values.receivables,
            {
              receivable_id: currentItem._id,
              tipoDeBaixa: "T",
              valor: currentItem.valor,
              dataBaixa: format(new Date(), "yyyy-MM-dd"),
            },
          ]);
          setReceivables([...receivables, currentItem]);
        }
      }

      setCurrentItem(null);
      setShowAddPayable(false);
      setShowAddReceivable(false);
    }
  }, [currentItem]);

  const removeItem = (id, type) => {
    formik.setFieldValue(
      type === "payable" ? "payables" : "receivables",
      formik.values[showAddPayable ? "payables" : "receivables"].filter(
        (item) => item !== id
      )
    );

    if (type === "payable") {
      setPayables(payables.filter((item) => item._id !== id));
    } else {
      setReceivables(receivables.filter((item) => item._id !== id));
    }
  };

  const detailItem = (id, type) => {
    if (type === "payable") {
      setShowDatailPayable(true);
      setCurrentItem(payables.find((item) => item._id === id));
    } else {
      setShowDetailReceivable(true);
      setCurrentItem(receivables.find((item) => item._id === id));
    }
  };

  const updatePayablesFormik = (_id, newValues) => {
    if (newValues.valorPago) {
      newValues.valor = newValues.valorPago;
    }

    if (newValues.tipoDeBaixa === "T") {
      newValues.valorPago = 0;
      newValues.valor = payables.find((item) => item._id === _id).valor;
      setPayables(
        payables.map((item) => {
          if (item._id === _id) {
            return {
              ...item,
              ...newValues,
            };
          }
          return item;
        })
      );
    }

    const updatedPayables = formik.values.payables.map((item) => {
      if (item.payable_id === _id) {
        return {
          ...item,
          ...newValues,
        };
      }
      return item;
    });

    formik.setFieldValue("payables", updatedPayables);
  };

  const updateReceivablesFormik = (_id, newValues) => {
    if (newValues.valorPago) {
      newValues.valor = newValues.valorPago;
    }

    if (newValues.tipoDeBaixa === "T") {
      newValues.valorPago = 0;
      newValues.valor = receivables.find((item) => item._id === _id).valor;
      setReceivables(
        receivables.map((item) => {
          if (item._id === _id) {
            return {
              ...item,
              ...newValues,
            };
          }
          return item;
        })
      );
    }

    const updatedReceivables = formik.values.receivables.map((item) => {
      if (item.receivable_id === _id) {
        return {
          ...item,
          ...newValues,
        };
      }
      return item;
    });

    formik.setFieldValue("receivables", updatedReceivables);
  };

  const limitText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  const columns = [
    {
      Header: "N. P",
      accessor: "numPedido",
      align: "left",
    },
    {
      Header: "Partida Contábil",
      accessor: "partidaContabil",
      align: "left",
    },
    {
      Header: "Descrição",
      accessor: "descricao",
      align: "left",
    },
    {
      Header: "Fornecedor",
      accessor: "supplier",
      align: "left",
    },
    {
      Header: "Valor",
      accessor: "valor",
      align: "right",
    },
    {
      Header: "Tipo de Baixa",
      accessor: "tipoDeBaixa",
      align: "center",
    },
    {
      Header: "Valor Pago",
      accessor: "valorPago",
      align: "right",
    },
    {
      Header: "Data de PAGMTO",
      accessor: "dataBaixa",
      align: "center",
    },
    {
      Header: "Ação",
      accessor: "action",
      align: "center",
    },
  ];

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Lote de Baixa
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="nome"
                    name="nome"
                    label="Nome do Lote"
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                    helperText={formik.touched.nome && formik.errors.nome}
                  />

                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição do Lote"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.descricao &&
                      Boolean(formik.errors.descricao)
                    }
                    helperText={
                      formik.touched.descricao && formik.errors.descricao
                    }
                  />

                  <TextField
                    id="account"
                    name="account"
                    label="Selecione a conta bancária de origem"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.account && Boolean(formik.errors.account)
                    }
                    helperText={formik.touched.account && formik.errors.account}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList.accounts.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="dataPagamento"
                    name="dataPagamento"
                    label="Data do Pagamento"
                    type="date"
                    value={formik.values.dataPagamento}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dataPagamento &&
                      Boolean(formik.errors.dataPagamento)
                    }
                    helperText={
                      formik.touched.dataPagamento &&
                      formik.errors.dataPagamento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onFocus={() => setFocusedDataPagamento(true)}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setFocusedDataPagamento(false);
                    }}
                  />
                  {focusedDataPagamento && (
                    <>
                      <div></div>
                      <MDTypography variant="body2" color="textSecondary">
                        Preenchendo este campo, você irá definir esta data de
                        pagamento para todos os títulos.
                      </MDTypography>
                    </>
                  )}

                  <Grid
                    container
                    sx={{
                      gridColumn: "span 2",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ mx: "auto" }}
                      mb={2}
                    >
                      <Tabs
                        orientation={tabsOrientation}
                        value={tabValue}
                        onChange={handleSetTabValue}
                        className={classes.tabs}
                        ref={refAddItem}
                      >
                        <Tab label="Contas à Pagar" icon={<InventoryIcon />} />
                        <Tab label="Contas à Receber" icon={<LocalAtmIcon />} />
                      </Tabs>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ mx: "auto" }}
                      mb={2}
                    >
                      {tabValue === 0 && (
                        <div>
                          <div
                            style={{
                              gridColumn: "span 2",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <MDButton
                              variant="contained"
                              color="info"
                              type="button"
                              onClick={() => {
                                scrollToTop();
                                setShowAddPayable(true);
                              }}
                            >
                              Adicionar Item
                            </MDButton>
                          </div>

                          {payables.length === 0 && (
                            <div
                              style={{
                                gridColumn: "span 2",
                                textAlign: "center",
                              }}
                            >
                              <MDTypography
                                variant="body2"
                                color="textSecondary"
                              >
                                Nenhum item adicionado
                              </MDTypography>
                            </div>
                          )}

                          {payables.length > 0 && (
                            <DataTable
                              table={{
                                columns,
                                rows: payables.map((row) => {
                                  return {
                                    ...row,
                                    numPedido:
                                      row?.purchase?.numPedido ||
                                      row?.purchaseWarehouse?.numPedido,
                                    partidaContabil:
                                      row?.partidaContabil || "-",
                                    descricao: row?.descricao,
                                    supplier:
                                      row?.supplier?.razaoSocial ||
                                      row?.supplier?.nomeFantasia,
                                    valor: Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(row?.valor),
                                    tipoDeBaixa: (
                                      <>
                                        <TextField
                                          id={`tipoDeBaixa-${row?._id}`}
                                          name={`tipoDeBaixa-${row?._id}`}
                                          label="Tipo de Baixa"
                                          value={row?.tipoDeBaixa || ""}
                                          onChange={(e) => {
                                            updatePayablesFormik(row?._id, {
                                              tipoDeBaixa: e.target.value,
                                            });
                                            payables.find(
                                              (item) => item._id === row?._id
                                            ).tipoDeBaixa = e.target.value;
                                          }}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                          InputLabelProps={{ shrink: true }}
                                        >
                                          <option value="T">Total</option>
                                          <option value="P">Parcial</option>
                                        </TextField>
                                      </>
                                    ),
                                    valorPago:
                                      row?.tipoDeBaixa === "P" ? (
                                        <CurrencyInput
                                          id={`valor-${row?._id}`}
                                          name={`valor-${row?._id}`}
                                          label="Valor"
                                          value={dotToComma(
                                            row?.valorPago || 0
                                          )}
                                          decimalsLimit={2}
                                          onValueChange={(val) => {
                                            if (val >= row?.valor) {
                                              alert(
                                                "O valor pago parcialmente não pode ser maior ou igual ao valor total."
                                              );
                                              val = 0;
                                            }
                                            updatePayablesFormik(row?._id, {
                                              valorPago: commaToDot(val),
                                            });
                                            payables.find(
                                              (item) => item._id === row?._id
                                            ).valorPago = commaToDot(val);
                                          }}
                                          customInput={TextField}
                                          sx={{
                                            width: "100%",
                                          }}
                                          prefix="R$ "
                                          intlConfig={{
                                            locale: "pt-BR",
                                            currency: "BRL",
                                          }}
                                        />
                                      ) : (
                                        Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(row?.valor || 0)
                                      ),
                                    dataBaixa: (
                                      <TextField
                                        key={`dataBaixa-${row?._id}`}
                                        id={`dataBaixa-${row?._id}`}
                                        name={`dataBaixa-${row?._id}`}
                                        label="Data de Baixa"
                                        value={row?.dataBaixa}
                                        onChange={(e) => {
                                          updatePayablesFormik(row?._id, {
                                            dataBaixa: e.target.value,
                                          });
                                          payables.find(
                                            (item) => item._id === row?._id
                                          ).dataBaixa = e.target.value;
                                        }}
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    ),
                                    action: (
                                      <>
                                        <MDButton
                                          variant="gradient"
                                          color="error"
                                          onClick={() =>
                                            removeItem(row?._id, "payable")
                                          }
                                        >
                                          <Icon fontSize="small">
                                            delete_forever
                                          </Icon>
                                        </MDButton>

                                        <MDButton
                                          variant="gradient"
                                          color="success"
                                          onClick={() =>
                                            detailItem(row?._id, "payable")
                                          }
                                          iconOnly
                                          sx={{
                                            marginLeft: 1,
                                          }}
                                        >
                                          <Icon fontSize="small">
                                            visibility
                                          </Icon>
                                        </MDButton>
                                      </>
                                    ),
                                  };
                                }),
                              }}
                              isSorted={false}
                              disablePagination={true}
                              noEndBorder
                            />
                          )}
                        </div>
                      )}

                      {tabValue === 1 && (
                        <div>
                          <div
                            style={{
                              gridColumn: "span 2",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <MDButton
                              variant="contained"
                              color="info"
                              type="button"
                              onClick={() => {
                                scrollToTop();
                                setShowAddReceivable(true);
                              }}
                            >
                              Adicionar Item
                            </MDButton>
                          </div>

                          {receivables.length === 0 && (
                            <div
                              style={{
                                gridColumn: "span 2",
                                textAlign: "center",
                              }}
                            >
                              <MDTypography
                                variant="body2"
                                color="textSecondary"
                              >
                                Nenhum item adicionado
                              </MDTypography>
                            </div>
                          )}

                          {receivables.length > 0 && (
                            <DataTable
                              table={{
                                columns: columns.map((column) => {
                                  if (column.accessor === "supplier") {
                                    return {
                                      ...column,
                                      Header: "Cliente",
                                    };
                                  }
                                  return column;
                                }),
                                rows: receivables.map((row) => {
                                  return {
                                    ...row,
                                    numPedido:
                                      row?.sale?.numPedido ||
                                      row?.saleWarehouse?.numPedido,
                                    partidaContabil:
                                      row?.partidaContabil || "-",
                                    descricao: row?.descricao,
                                    supplier:
                                      row?.customer?.razaoSocial ||
                                      row?.customer?.nomeFantasia,
                                    valor: Intl.NumberFormat("pt-BR", {
                                      style: "currency",
                                      currency: "BRL",
                                    }).format(row?.valor),
                                    tipoDeBaixa: (
                                      <>
                                        <TextField
                                          id={`tipoDeBaixa-${row?._id}`}
                                          name={`tipoDeBaixa-${row?._id}`}
                                          label="Tipo de Baixa"
                                          value={row?.tipoDeBaixa || ""}
                                          onChange={(e) => {
                                            updateReceivablesFormik(row?._id, {
                                              tipoDeBaixa: e.target.value,
                                            });
                                            receivables.find(
                                              (item) => item._id === row?._id
                                            ).tipoDeBaixa = e.target.value;
                                          }}
                                          select
                                          SelectProps={{
                                            native: true,
                                          }}
                                          InputLabelProps={{ shrink: true }}
                                        >
                                          <option value="T">Total</option>
                                          <option value="P">Parcial</option>
                                        </TextField>
                                      </>
                                    ),
                                    valorPago:
                                      row?.tipoDeBaixa === "P" ? (
                                        <CurrencyInput
                                          id={`valor-${row?._id}`}
                                          name={`valor-${row?._id}`}
                                          label="Valor"
                                          value={dotToComma(
                                            row?.valorPago || 0
                                          )}
                                          decimalsLimit={2}
                                          onValueChange={(val) => {
                                            if (val >= row?.valor) {
                                              alert(
                                                "O valor pago parcialmente não pode ser maior ou igual ao valor total."
                                              );
                                              val = 0;
                                            }
                                            updateReceivablesFormik(row?._id, {
                                              valorPago: commaToDot(val),
                                            });
                                            receivables.find(
                                              (item) => item._id === row?._id
                                            ).valorPago = commaToDot(val);
                                          }}
                                          customInput={TextField}
                                          sx={{
                                            width: "100%",
                                          }}
                                          prefix="R$ "
                                          intlConfig={{
                                            locale: "pt-BR",
                                            currency: "BRL",
                                          }}
                                        />
                                      ) : (
                                        Intl.NumberFormat("pt-BR", {
                                          style: "currency",
                                          currency: "BRL",
                                        }).format(row?.valor || 0)
                                      ),
                                    dataBaixa: (
                                      <TextField
                                        key={`dataBaixa-${row?._id}`}
                                        id={`dataBaixa-${row?._id}`}
                                        name={`dataBaixa-${row?._id}`}
                                        label="Data de Baixa"
                                        value={row?.dataBaixa}
                                        onChange={(e) => {
                                          updateReceivablesFormik(row?._id, {
                                            dataBaixa: e.target.value,
                                          });
                                          receivables.find(
                                            (item) => item._id === row?._id
                                          ).dataBaixa = e.target.value;
                                        }}
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                      />
                                    ),
                                    action: (
                                      <>
                                        <MDButton
                                          variant="gradient"
                                          color="error"
                                          onClick={() =>
                                            removeItem(row?._id, "receivable")
                                          }
                                        >
                                          <Icon fontSize="small">
                                            delete_forever
                                          </Icon>
                                        </MDButton>

                                        <MDButton
                                          variant="gradient"
                                          color="success"
                                          onClick={() =>
                                            detailItem(row?._id, "receivable")
                                          }
                                          sx={{
                                            marginLeft: 1,
                                          }}
                                        >
                                          <Icon fontSize="small">
                                            visibility
                                          </Icon>
                                        </MDButton>
                                      </>
                                    ),
                                  };
                                }),
                              }}
                              isSorted={false}
                              disablePagination={true}
                              noEndBorder
                            />
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Lote de baixa cadastrado com sucesso!"
                          : "Ocorreu um erro ao cadastrar o lote de baixa."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddPayable && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarContaAPagar
              setShowAddPayable={setShowAddPayable}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent={true}
              onlyPossiveis={true}
            />
          </MDBox>
        )}

        {showDatailPayable && (
          <DetalharContaAPagar
            setShowDetailState={setShowDatailPayable}
            currentIdAction={currentItem?._id}
            fetchData={() => {}}
          />
        )}

        {showDetailReceivable && (
          <DetalharContaAReceber
            setShowDetailState={setShowDetailReceivable}
            currentIdAction={currentItem?._id}
            fetchData={() => {}}
          />
        )}

        {showAddReceivable && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarContaAReceber
              setShowAddReceivable={setShowAddReceivable}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent={true}
              onlyPossiveis={true}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
