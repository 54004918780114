import { Box, Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import { format } from "utils/formatDate";

const Field = ({ label, value }) => (
  <TextField
    id={label}
    label={label}
    variant="outlined"
    fullWidth
    value={value}
    disabled
  />
);

export default function DetalharSaidaAlmoxarifado({ row, setShowDetailState }) {
  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        setShowDetailState(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  const fields = [
    { label: "Produto", value: row.nome },
    { label: "Lote/Série", value: row.lote },
    { label: "Unidade de Medida", value: row.unit },
    {
      label: "Validade",
      value: row.dataVencimento
        ? format(new Date(row.dataVencimento), "dd/MM/yyyy")
        : "",
    },
    {
      label: "Valor Unitário (R$)",
      value: row.valorUnitario?.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    },
    { label: "Quantidade Aplicada", value: row.qtd },
    {
      label: "Valor Total Aplicado (R$)",
      value: row.valorTotal?.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
    },
    {
      label: "Data de Aplicação",
      value: format(new Date(row.data), "dd/MM/yyyy"),
    },
    { label: "Centro de Custo em que foi aplicado", value: row.centroDeCusto },
    {
      label: "Data da sincronização dos dados",
      value: format(new Date(row.dataSincronizacao), "dd/MM/yyyy"),
    },
    { label: "Responsável pela aplicação", value: row.responsavel },
  ];

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Visualizar Aplicação
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    setShowDetailState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                >
                  {fields.map((field) => (
                    <Field key={field.label} {...field} />
                  ))}
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
