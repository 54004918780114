import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function DashboardPedidos() {
  const url =
    "https://app.powerbi.com/view?r=eyJrIjoiMmM2ZjJmNTEtYjZjNS00NjYzLWJjNjktODJlMWQwYmRiNzZjIiwidCI6IjdjYjkzNTM2LTQ3MzYtNDQ0NC04ZGI0LTljOTExNjg3ODRlZSJ9";

  return (
    <DashboardLayout>
      <iframe
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        style={{
          border: 0,
          width: "100%",
          height: "1025px",
        }}
        src={url}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default DashboardPedidos;
