import HttpService from "./http.service";

class BatchOfSettlementsService {
  list = async (sortStr = null, filterStr = null) => {
    const batchesEndpoint = `/batch-of-settlements?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(batchesEndpoint);
  };

  paginate = async (page = 0, limit = 25, sortStr = null, filterStr = null) => {
    const batchesEndpoint = `/batch-of-settlements?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(batchesEndpoint);
  };

  get = async (id) => {
    const batchesEndpoint = "/batch-of-settlements";
    return await HttpService.get(`${batchesEndpoint}/${id}`);
  };

  getByPayable = async (id) => {
    const batchesEndpoint = `/batch-of-settlements/payable/${id}`;
    return await HttpService.get(batchesEndpoint);
  };

  getByReceivable = async (id) => {
    const batchesEndpoint = `/batch-of-settlements/receivable/${id}`;
    return await HttpService.get(batchesEndpoint);
  };

  create = async (data) => {
    const batchesEndpoint = "/batch-of-settlements";
    return await HttpService.post(batchesEndpoint, data);
  };

  update = async (id, data) => {
    const batchesEndpoint = "/batch-of-settlements";
    return await HttpService.put(`${batchesEndpoint}/${id}`, data);
  };

  delete = async (id, data) => {
    const batchesEndpoint = "/batch-of-settlements";
    return await HttpService.delete(`${batchesEndpoint}/${id}`, data);
  };
}

export default new BatchOfSettlementsService();
