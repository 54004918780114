import { Icon, Tooltip } from "@mui/material";
import { format } from "utils/formatDate";
import { useEffect, useState } from "react";
import Service from "services/stock-transactions";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export default function data(setLoading = () => {}, warehouseId, handleDetail) {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [sortStr, setSortStr] = useState("&sortBy=date&sortOrder=-1");
  const [filterStr, setFilterStr] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    const { data, limit, total } = await Service.paginate(
      warehouseId,
      page,
      perPage,
      sortStr,
      filterStr
    );
    setPerPage(limit);
    setTotalRows(total);

    setRows(
      data.map((row) =>
        Object({
          id: row._id,
          nome: row.nome,
          qtd: row.qtd,
          data: format(new Date(row.data), "dd/MM/yyyy"),
          type: row.type === "input" ? "Entrada" : "Saída",
          unit: row.unit,
          responsavel: row.responsavel,
          action: row,
        })
      )
    );

    setLoading(false);
  };
  const action = (props) => {
    const row = props?.row?.action;

    return (
      <MDBox display="flex" gap={1}>
        <Tooltip title="Detalhes">
          <MDButton
            variant="gradient"
            color="success"
            fullWidth
            type="submit"
            onClick={() => handleDetail(row)}
            iconOnly
          >
            <Icon fontSize="small">visibility</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
    );
  };
  const updatePagination = ({ page: newPage, pageSize: newPerPage }) => {
    setPage(newPage);
    setPerPage(newPerPage);
  };

  const sortBy = (sortArr) => {
    if (sortArr.length === 0) {
      setSortStr(null);
      return;
    }

    const [filter] = sortArr;

    setSortStr(
      `&sortBy=${filter.field}&sortOrder=${filter.sort === "asc" ? 1 : "-1"}`
    );
  };

  const filterBy = ({ items }) => {
    if (items.length === 0) {
      setFilterStr(null);
      return;
    }

    const [filter] = items;

    if (filter.operator === "contains" && filter.value === undefined) {
      return;
    }

    setFilterStr(
      `&filterField=${filter.field}&filterOperator=${filter.operator}&filterValue=${filter.value}`
    );
  };

  useEffect(() => {
    warehouseId && fetchData();
  }, [page, perPage, sortStr, filterStr, warehouseId]);

  return {
    columns: [
      { headerName: "Data", field: "data", flex: 0.3 },
      { headerName: "Tipo", field: "type", flex: 0.3 },
      { headerName: "Produto", field: "nome", flex: 0.3 },
      { headerName: "Quantidade", field: "qtd", flex: 0.3 },
      { headerName: "Unid. Medida", field: "unit", flex: 0.3 },
      { headerName: "Responsável", field: "responsavel", flex: 0.3 },
      { headerName: "Ações", field: "action", renderCell: action },
    ],

    rows,
    fetchData,
    updatePagination,
    sortBy,
    filterBy,
    pageObject: {
      totalRows,
      perPage,
      page,
    },
  };
}
