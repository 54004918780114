import Contas from "layouts/pedidos-compra/components/contas";

export default function ContasVenda({
  formik,
  refAddItem,
  isDetalhar = false,
  cliente,
  valorTotal,
  refreshParent = () => {},
  dataPedido,
}) {
  return (
    <>
      <Contas
        formik={formik}
        refAddItem={refAddItem}
        isDetalhar={isDetalhar}
        isReceber={true}
        cliente={cliente}
        valorTotal={valorTotal}
        refreshParent={refreshParent}
        dataPedido={dataPedido}
      />
    </>
  );
}
