// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { ptBR_DataGrid } from "utils/datagrid";

// Data
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { useEffect, useState } from "react";

import PedidoCompra from "layouts/pedidos-compra";
import AdicionarDado from "./components/addData";
import EditarDado from "./components/editData";
import tableData from "./components/tableData";

import Service from "services/sales";
import PermissionService from "services/permission";
import UsersService from "services/users";

import { Icon, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import { format } from "utils/formatDate";
import Filter from "layouts/pedidos-compra/components/filter";
import { exportToExcel } from "react-json-to-excel";
import { useLocation, useNavigate } from "react-router-dom";
import { dotToComma } from "utils/numberComma";
import {
  companies,
  findCompany,
  findStatusPedido,
  findTipoFrete,
  tpagEntries,
} from "./components/yupSchema";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { set } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { commaToDot } from "utils/numberComma";
import MDDebouncedTextField from "components/MDDebouncedTextField";
import HistoryModal from "./components/HistoryModal";
import { AREAS } from "layouts/permissoes/components/yupSchema";

export default function PedidosVenda() {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditState, setShowEditState] = useState(false);
  const [showImportState, setShowImportState] = useState(false);
  const [showFilterState, setShowFilterState] = useState(false);
  const [dialogRemove, setDialogRemove] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const [isFiltered, setIsFiltered] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});

  const [currentGerar, setCurrentGerar] = useState(null);
  const [descontoNfe, setDescontoNfe] = useState(0);
  const [informacoesNfe, setInformacoesNfe] = useState("");
  const [company, setCompany] = useState(companies.PRIMEMATRIZ);
  const [cfop, setCfop] = useState(6102);
  const [tpag, setTpag] = useState("01");
  const [xPed, setXPed] = useState("");

  const [openCorrigirNfe, setOpenCorrigirNfe] = useState(false);
  const [currentCorrigirNfe, setCurrentCorrigirNfe] = useState(null);
  const [currentCorrigirNumPedido, setCurrentCorrigirNumPedido] =
    useState(null);
  const [correcaoNfe, setCorrecaoNfe] = useState("");

  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyLogs, setHistoryLogs] = useState([]);

  const handleChangeDescontoNfe = (e) => {
    setDescontoNfe(e.target.value);
  };

  const handleChangeInformacoesNfe = (e) => {
    setInformacoesNfe(e.target.value);
  };

  const handleChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDetail = async (id) => {
    setLoading(true);
    const fileURL = process.env.REACT_APP_API_URL + "sales/" + id + "/pdf/view";
    window.open(fileURL, "_blank");
    setLoading(false);
  };

  const handleGerarNfe = async (row) => {
    setDescontoNfe(0);
    setInformacoesNfe("");
    setCurrentGerar(row);
  };

  const handleFinalizarNfe = async () => {
    setLoading(true);
    const data = {
      descontoNfe: commaToDot(descontoNfe),
      informacoesNfe,
      company,
      cfop,
      tpag,
      xPed,
    };

    try {
      const response = await Service.gerarNfe(currentGerar._id, data);

      if (response.sucesso) {
        alert("NFE Gerada com Sucesso, Aguarde o Integra Notas processar.");
      } else {
        let mensagem = response.mensagem;

        if (Array.isArray(response.erros) && response.erros.length > 0) {
          mensagem += "\n\n";
          for (let erro of response.erros) {
            mensagem += erro.descricao + "\n";
          }
        }

        alert(mensagem);
      }
    } catch (error) {
      console.error(error);
      alert("Erro ao Gerar NFE");
    }

    setCurrentGerar(null);
    setDescontoNfe(0);
    setInformacoesNfe("");
    fetchData();
    setLoading(false);
  };

  const handleVerNfe = async () => {
    setLoading(true);
    const data = {
      descontoNfe: commaToDot(descontoNfe),
      informacoesNfe,
      company,
      cfop,
      tpag,
      xPed,
      preview: true,
    };

    try {
      const response = await Service.gerarNfe(currentGerar._id, data);

      if (response.sucesso) {
        const urlFrom64 = response.pdf;
        const link = document.createElement("a");
        link.href = "data:application/pdf;base64," + urlFrom64;
        link.target = "_blank";
        link.click();
      } else {
        let mensagem = response.mensagem;

        if (Array.isArray(response.erros) && response.erros.length > 0) {
          mensagem += "\n\n";
          for (let erro of response.erros) {
            mensagem += erro.descricao + "\n";
          }
        }

        alert(mensagem);
      }
    } catch (error) {
      console.error(error);
      alert("Erro ao Gerar NFE");
    }

    setLoading(false);
  };

  const handleDownloadNfe = async (id) => {
    setLoading(true);
    const fileURL = process.env.REACT_APP_API_URL + "sales/" + id + "/pdf/nfe";
    const response = await fetch(fileURL);

    if (response.status === 200) {
      window.open(fileURL, "_blank");
    } else {
      const data = await response.json();
      alert(`Erro ao gerar PDF da NFE: ${data.message}`);
    }

    setLoading(false);
  };

  const handleGerarXmlNfe = async (id) => {
    setLoading(true);
    const fileURL = process.env.REACT_APP_API_URL + "sales/" + id + "/xml/nfe";
    const response = await fetch(fileURL);

    if (response.status === 200) {
      window.open(fileURL, "_blank");
    } else {
      const data = await response.json();
      alert(`Erro ao gerar XML da NFE: ${data.message}`);
    }

    setLoading(false);
  };

  const handleCancelarNfe = async (id) => {
    if (!window.confirm("Deseja realmente cancelar a NFE deste Pedido?")) {
      return;
    }

    setLoading(true);
    try {
      const response = await Service.cancelNfe(id);
      alert(response.message);
      fetchData();
    } catch (error) {
      console.error(error);
      alert(`Erro ao Cancelar NFE: ${error.message}`);
    }

    setLoading(false);
  };

  const handleDesvincularNfe = async (id) => {
    if (
      !window.confirm(
        "Deseja realmente desvincular a NFE deste Pedido? (Ela apenas será desvinculada porém não será cancelada!)"
      )
    ) {
      return;
    }

    setLoading(true);
    try {
      const response = await Service.removerNfe(id);
      fetchData();
    } catch (error) {
      console.error(error);
      alert(`Erro ao Desvincular NFE: ${error.message}`);
    }

    setLoading(false);
  };

  const handleCorrigirNfe = async (id, numPedido) => {
    setOpenCorrigirNfe(true);
    setCurrentCorrigirNfe(id);
    setCurrentCorrigirNumPedido(numPedido);
    setCorrecaoNfe("");
    setCfop(6102);
    setTpag("01");
  };

  const handleFinalizarCorrecaoNfe = async () => {
    if (correcaoNfe.length < 15 || correcaoNfe.length > 1000) {
      alert("Texto da correção deve ter entre 15 e 1000 caracteres");
      return;
    }

    setLoading(true);
    try {
      const response = await Service.fixNfe(currentCorrigirNfe, {
        justificativa: correcaoNfe,
      });
      alert(response.message);
      fetchData();
      setOpenCorrigirNfe(false);
    } catch (error) {
      console.error(error);
      alert(`Erro ao Enviar Carta de Correção: ${error.message}`);
    }

    setLoading(false);
  };

  const handleEdit = (id) => {
    setCurrentIdAction(id);
    setShowEditState(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (obj) => {
    setDialogRemove(true);
    setDialogText(obj.numPedido);
    setCurrentIdAction(obj._id);
  };

  const handleFetchLogs = async (id) => {
    setHistoryLogs([]);
    try {
      const logs = await Service.logs(id);

      setHistoryLogs(logs);
      setShowHistoryModal(true);
    } catch {
      alert("Falha em puxar os logs, contate adm.");
    }
  };

  const cancelDelete = () => {
    setDialogRemove(false);
    setDialogText("");
    setCurrentIdAction(null);
  };

  const confirmDelete = async () => {
    await Service.delete(currentIdAction);
    setDialogRemove(false);
    fetchData();
  };

  const {
    columns,
    rows,
    fetchData,
    updateRows,
    updatePagination,
    sortBy,
    filterBy,
    pageObject,
    sortStr,
    filterStr,
  } = tableData(
    handleEdit,
    handleDelete,
    handleDetail,
    handleGerarNfe,
    handleDownloadNfe,
    handleGerarXmlNfe,
    handleCancelarNfe,
    handleDesvincularNfe,
    handleCorrigirNfe,
    handleFetchLogs,
    isAdmin,
    setLoadingTable
  );

  const selectParent = async (pedido) => {
    if (!pedido.numPedido) {
      throw new Error("Número do Pedido é obrigatório");
    }

    setLoading(true);

    const sale = await Service.import({ numPedido: pedido.numPedido });
    setShowImportState(false);
    setCurrentIdAction(sale._id);
    setShowEditState(true);

    setLoading(false);
  };

  const resetRows = () => {
    updateRows([]);
    setLoading();
    setIsFiltered(false);
    setCurrentFilter({});
    fetchData();
  };

  const doCurrentFilter = async () => {
    if (JSON.stringify(currentFilter) !== "{}") {
      setLoading(true);
      const data = await Service.filter(currentFilter);
      updateRows(data);
      setLoading(false);
    } else {
      resetRows();
    }
  };

  const productsToString = (products) => {
    return products
      .map((product) =>
        `${product.product.descricao} - ${product.quantity} ${
          product.measure || ""
        }`.trim()
      )
      .join(", ");
  };

  const cleanRawRows = (lines) => {
    const linesToExport = lines.map((line) => ({
      "No. Pedido": line.numPedido,
      Data: line.data ? format(new Date(line.data), "dd/MM/yyyy") : "",
      Status: findStatusPedido(line.status),
      "Produto x Quantidade": productsToString(line.products),
      "Valor Total dos Produtos": dotToComma(line.valorTotal),
      Cliente: line.customer?.nomeFantasia || line.customer?.razaoSocial || "",
      Vendedor: line.user?.name || "",
      Transportadora: line.shippingCompany?.nome || "",
      Agenciador: line.agent?.nome || "",
      "Peso Bruto": line.pesoBruto,
      Tara: line.tara,
      "Peso Liquido": line.pesoLiquido,
      "Qtd. Frutas": line.qtdFrutas,
      Media: line.media,
      "Preço por Tonelada": dotToComma(line.precoTonelada),
      "Data de Descarga": line.dataDescarga
        ? format(new Date(line.dataDescarga), "dd/MM/yyyy")
        : "",
      "Valor do Frete": dotToComma(line.valorFrete),
      "Valor do ICMS": dotToComma(line.valorIcms),
      Carregamento: dotToComma(line.carregamento),
      "Adiantamento do Frete": dotToComma(line.adiantamentoFrete),
      "Total a Pagar do Frete": dotToComma(line.totalPagarFrete),
      Motorista: line.motorista,
      "Tipo de Frete": findTipoFrete(line.tipoFrete),
      Caminhão: line.caminhao,
      Telefone: line.telefone,
      "Valor Final": dotToComma(line.totalFinal),
      Observações: line.observacoes || "",
    }));

    return linesToExport;
  };

  const doExportExcel = async () => {
    setLoading(true);

    const { data } = await Service.paginate(0, -1, sortStr, filterStr);
    exportToExcel(cleanRawRows(data), "prime_" + new Date().getTime());
    setLoading(false);
  };

  const doExportExcelPage = async () => {
    setLoading(true);

    const { data } = await Service.paginate(
      pageObject.page,
      pageObject.perPage,
      sortStr,
      filterStr
    );
    exportToExcel(cleanRawRows(data), "prime_" + new Date().getTime());
    setLoading(false);
  };

  useEffect(() => {
    if (location.state && location.state.id) {
      setCurrentIdAction(location.state.id);
      setShowEditState(true);
    }

    if (location.state && location.state.pedidosAguardandoFechamento) {
      setLoadingTable(true);
      setCurrentFilter({
        aguardandoFechamento: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await Service.filter({
          aguardandoFechamento: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
        setLoadingTable(false);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }

    // pedidosVendaSemContasReceber

    if (location.state && location.state.pedidosVendaSemContasReceber) {
      setLoadingTable(true);
      setCurrentFilter({
        semContasReceber: "S",
      });

      const doFilterLocation = async () => {
        const newRows = await Service.filter({
          semContasReceber: "S",
        });

        updateRows(newRows);
        setIsFiltered(true);
        setLoadingTable(false);
      };

      setTimeout(() => {
        doFilterLocation();
      }, 3000);
    }
  }, [location, loading]);

  useEffect(() => {
    if (location.state && location.state.id && showEditState === false) {
      navigate("/pedidos-venda", { state: { id: null } });
    }
  }, [showEditState]);

  useEffect(() => {
    const fetchIsAdmin = async () => {
      const permissions = await PermissionService.listMy();
      const permissionsFiltered = permissions.filter(
        (permission) => permission.area === AREAS.SALE
      );

      setIsAdmin(permissionsFiltered.shift()?.editaAdmin === "1");
    };

    fetchIsAdmin();

    const fetchUsers = async () => {
      const users = await UsersService.getOnlyNames();
      setUsers(users);
    };

    fetchUsers();
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        {showEditState && (
          <EditarDado
            setShowEditState={setShowEditState}
            currentIdAction={currentIdAction}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
          />
        )}
        {showAddState && (
          <AdicionarDado
            setShowAddState={setShowAddState}
            fetchData={isFiltered ? doCurrentFilter : fetchData}
          />
        )}
        {showFilterState && (
          <Filter
            Service={Service}
            resetRows={resetRows}
            updateRows={updateRows}
            setIsFiltered={setIsFiltered}
            setShowFilterState={setShowFilterState}
            isPedidoVenda={true}
            setCurrentFilter={setCurrentFilter}
          />
        )}
        {showImportState && (
          <MDBox
            p={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <PedidoCompra
              fromParent={true}
              setShowImportState={setShowImportState}
              selectParent={selectParent}
            />
          </MDBox>
        )}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Pedidos de Venda
                </MDTypography>
                <div>
                  {!matchesSm && (
                    <>
                      <Tooltip title="Ações">
                        <MDButton
                          variant="contained"
                          fullWidth
                          type="button"
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          iconOnly
                          display={{ xs: "block", md: "hidden" }}
                        >
                          <Icon fontSize="small">more_vert</Icon>
                        </MDButton>
                      </Tooltip>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setShowFilterState(true);
                          }}
                        >
                          Filtrar
                          {isFiltered && (
                            <>
                              {" "}
                              <Icon fontSize="small">filter_alt</Icon>
                            </>
                          )}
                        </MenuItem>

                        <MenuItem
                          onClick={() => setShowAddState(true)}
                          disabled={loading}
                        >
                          Adicionar
                        </MenuItem>
                      </Menu>
                    </>
                  )}

                  {matchesSm && (
                    <>
                      <MDButton
                        variant="contained"
                        type="button"
                        onClick={() => setShowFilterState(true)}
                        sx={{ mr: 1 }}
                      >
                        Filtrar
                        {isFiltered && (
                          <>
                            {" "}
                            <Icon fontSize="small">filter_alt</Icon>
                          </>
                        )}
                      </MDButton>

                      <MDButton
                        variant="contained"
                        type="button"
                        onClick={() => setShowImportState(true)}
                        sx={{ mr: 1 }}
                        disabled={loading}
                      >
                        Importar
                      </MDButton>

                      <MDButton
                        variant="contained"
                        type="button"
                        onClick={() => setShowAddState(true)}
                        disabled={loading}
                      >
                        Adicionar
                      </MDButton>
                    </>
                  )}
                </div>
              </MDBox>
              <MDBox pt={3}>
                <Card sx={{ padding: 2 }}>
                  {isFiltered && "Filtrando Registros"}

                  {isFiltered ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSizeOptions={[50, 100]}
                      rowSelection={false}
                      loading={loading}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                    />
                  ) : (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      rowCount={pageObject.totalRows}
                      pageSizeOptions={[50, 100]}
                      paginationModel={{
                        pageSize: pageObject.perPage,
                        page: pageObject.page,
                      }}
                      paginationMode="server"
                      onPaginationModelChange={updatePagination}
                      sortingMode="server"
                      onSortModelChange={sortBy}
                      filterMode="server"
                      onFilterModelChange={filterBy}
                      rowSelection={false}
                      loading={loadingTable}
                      localeText={ptBR_DataGrid}
                      sx={{
                        ".MuiDataGrid-virtualScroller": {
                          minHeight: "100px",
                        },
                      }}
                      disableColumnFilter
                      headerFilters
                      slots={{
                        headerFilterMenu: null,
                      }}
                    />
                  )}
                </Card>
              </MDBox>
            </Card>

            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <MDButton
                variant="gradient"
                color="success"
                onClick={doExportExcelPage}
                sx={{ mr: 1 }}
              >
                Exportar Página Para Excel{" "}
                <Icon fontSize="small">download</Icon>
              </MDButton>

              <MDButton
                variant="gradient"
                color="success"
                onClick={doExportExcel}
              >
                Exportar Tudo Para Excel <Icon fontSize="small">download</Icon>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>

        <MDDialog
          open={currentGerar !== null}
          handleClose={() => setDialogRemove(false)}
          title={`NFE Referente ao Pedido ${currentGerar?.numPedido}`}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => handleFinalizarNfe()}
              >
                Enviar DANFE
              </MDButton>
              <MDButton
                variant="gradient"
                color="warning"
                fullWidth
                type="submit"
                onClick={() => handleVerNfe()}
              >
                Ver DANFE
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => setCurrentGerar(null)}
              >
                Cancelar
              </MDButton>
            </>
          }
          sx={{
            ".MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          }}
        >
          <div>
            Valor Total:{" "}
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(currentGerar?.totalFinal)}
          </div>

          <br />

          <div>
            <TextField
              id="empresa"
              name="empresa"
              label="Empresa"
              value={company}
              onChange={handleChangeCompany}
              disabled={loading}
              sx={{
                width: "100%",
              }}
              select
              SelectProps={{
                native: true,
              }}
            >
              {Object.values(companies).map((opcao) => (
                <option key={opcao} value={opcao}>
                  {findCompany(opcao)}
                </option>
              ))}
            </TextField>
          </div>

          <br />

          <div>
            <TextField
              type="number"
              id="percentualDesconto"
              name="percentualDesconto"
              label="Percentual de Desconto"
              value={descontoNfe}
              min={0}
              max={100}
              onChange={handleChangeDescontoNfe}
              onKeyDown={(e) => {
                if (
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.keyCode !== 8 &&
                  e.keyCode !== 37 &&
                  e.keyCode !== 39
                ) {
                  e.preventDefault();
                }
              }}
              disabled={loading}
              sx={{
                width: "100%",
              }}
            />
          </div>

          <br />

          <div>
            <TextField
              select
              id="cfop"
              name="cfop"
              label="CFOP"
              value={cfop}
              onChange={(e) => setCfop(e.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              <option value={6102}>6102</option>
              <option value={5102}>5102</option>
            </TextField>
          </div>

          <br />

          <div>
            <TextField
              select
              id="tpag"
              name="tpag"
              label="Tipo de Pagamento"
              value={tpag}
              onChange={(e) => setTpag(e.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              {tpagEntries &&
                Object.entries(tpagEntries)
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
            </TextField>
          </div>

          <br />

          <div>
            <TextField
              type="text"
              id="xPed"
              name="xPed"
              label="Número do Pedido (xPed)"
              value={xPed}
              onChange={(e) => setXPed(e.target.value)}
              disabled={loading}
              sx={{
                width: "100%",
              }}
            />
          </div>

          <br />

          <div>
            <TextField
              type="text"
              multiline
              rows={4}
              id="informacoesNfe"
              name="informacoesNfe"
              label="Informações Adicionais"
              value={informacoesNfe}
              onChange={handleChangeInformacoesNfe}
              disabled={loading}
              sx={{
                width: "100%",
              }}
            />
          </div>
        </MDDialog>

        <MDDialog
          open={openCorrigirNfe}
          handleClose={() => setOpenCorrigirNfe(false)}
          title={`Carta Correção Referente ao Pedido ${currentCorrigirNumPedido}`}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => handleFinalizarCorrecaoNfe()}
              >
                Enviar Correção
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => setOpenCorrigirNfe(false)}
              >
                Cancelar
              </MDButton>
            </>
          }
          sx={{
            ".MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          }}
        >
          <br />
          <div>
            <TextField
              type="text"
              multiline
              rows={4}
              id="correcaoNfe"
              name="correcaoNfe"
              label="Texto da correção"
              value={correcaoNfe}
              onChange={(e) => setCorrecaoNfe(e.target.value)}
              disabled={loading}
              sx={{
                width: "100%",
              }}
            />
          </div>
        </MDDialog>

        {loading && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <MDTypography
              variant="h4"
              sx={{
                color: "white !important",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "20%",
              }}
            >
              Carregando...
            </MDTypography>
          </MDBox>
        )}

        <MDDialog
          open={dialogRemove}
          handleClose={() => setDialogRemove(false)}
          title={"Tem certeza que deseja remover?"}
          actions={
            <>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                onClick={() => confirmDelete()}
              >
                Confirmar
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => cancelDelete()}
              >
                Cancelar
              </MDButton>
            </>
          }
        >
          {dialogText}
        </MDDialog>

        <HistoryModal
          open={showHistoryModal}
          handleClose={() => {
            setShowHistoryModal(false);
            setHistoryLogs([]);
          }}
          logs={historyLogs}
          users={users}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
