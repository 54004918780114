import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useState } from "react";
import AdicionarConta from "./addConta";
import EditarDadoReceber from "layouts/receivables/components/editData";
import EditarDadoPagar from "layouts/payables/components/editData";
import { useEffect } from "react";
import { Icon, Tooltip } from "@mui/material";
import { commaToDot } from "utils/numberComma";
import {
  DIAS_PRAZO_PAGAMENTO,
  PARCELAS_PRAZO_PAGAMENTO,
  PRAZO_PAGAMENTO,
} from "./yupSchema";

export default function Contas({
  formik,
  refAddItem,
  isDetalhar = false,
  isReceber = false,
  cliente = null,
  valorTotal = null,
  refreshParent = () => {},
  dataPedido = null,
}) {
  const [showAddState, setShowAddState] = useState(false);
  const [showEditStateNew, setShowEditStateNew] = useState(false);
  const [currentEditNew, setCurrentEditNew] = useState(null);

  const [showEditStateCurr, setShowEditStateCurr] = useState(false);
  const [currentIdAction, setCurrentIdAction] = useState(null);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      Header: "Descrição",
      accessor: "descricao",
      align: "center",
    },
    {
      Header: "Valor",
      accessor: "valor",
      align: "center",
    },
    { Header: isDetalhar ? "" : "Ação", accessor: "action", align: "center" },
  ];

  const scrollToTop = () => {
    refAddItem?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const cleanBrl = (valor) => {
    if (typeof valor !== "string") {
      return valor;
    }

    if (valor.includes("R$")) {
      valor = valor.replace("R$", "").trim();
    }
    valor = valor.replace(/\./g, "");
    valor = commaToDot(valor);

    return valor;
  };

  const handleAdd = (conta) => {
    const newRows = rows.map((row) => ({
      ...row,
      valor: cleanBrl(row.valor),
    }));

    const contas = parseContaParcela(conta);

    for (let novaConta of contas) {
      newRows.push({
        ...novaConta,
        valor: Number(commaToDot(novaConta.valor)),
      });
    }

    setShowAddState(false);
    formik.setFieldValue("contas", newRows);
  };

  const handleEditCurrent = (conta) => {
    const newRows = [...rows];
    const idx = newRows.findIndex((row) => row._id === conta._id);
    newRows[idx] = conta;

    setShowEditStateNew(false);
    formik.setFieldValue("contas", newRows);
  };

  const parseContaParcela = (conta) => {
    const podeParcelar = [
      PRAZO_PAGAMENTO.TRINTA_QUARENTA_CINCO,
      PRAZO_PAGAMENTO.TRINTA_SESSENTA,
      PRAZO_PAGAMENTO.TRINTA_QUARENTA_CINCO_SESSENTA,
      PRAZO_PAGAMENTO.TRINTA_SESSENTA_NOVENTA,
    ];

    if (!podeParcelar.includes(conta.prazoPagamento) || !conta.prazoPagamento) {
      conta.valor = commaToDot(conta.valor);
      return [conta];
    }

    if (conta.valor.includes("R$")) {
      conta.valor = conta.valor.replace("R$", "").trim();
    }

    const parcelas = [];
    const valorParcela =
      commaToDot(conta.valor) / PARCELAS_PRAZO_PAGAMENTO[conta.prazoPagamento];
    const diasParcela = DIAS_PRAZO_PAGAMENTO[conta.prazoPagamento];

    for (let i = 0; i < PARCELAS_PRAZO_PAGAMENTO[conta.prazoPagamento]; i++) {
      parcelas.push({
        ...conta,
        descricao: `PARCELA ${i + 1}/${
          PARCELAS_PRAZO_PAGAMENTO[conta.prazoPagamento]
        }`,
        dataVencimento: hojePlusDias(diasParcela[i]),
        valor: valorParcela.toFixed(2),
        idx: Date.now() + i,
      });
    }

    return parcelas;
  };

  const hojePlusDias = (dias) => {
    const hoje = new Date();
    hoje.setDate(hoje.getDate() + dias);
    return hoje;
  };

  const handleEdit = (conta) => {
    if (conta?._id) {
      setShowEditStateCurr(true);
      setCurrentIdAction(conta._id);
    } else {
      setShowEditStateNew(true);
      setCurrentEditNew(conta);
    }
  };

  const handleDelete = (conta) => {
    if (!formik.values.contas || formik.values.contas.length === 0) return;

    let newRows = [...formik.values.contas];

    if (conta._id) {
      newRows = newRows.filter((row) => row._id !== conta._id);
    }

    if (conta.idx) {
      newRows = newRows.filter((row) => row.idx !== conta.idx);
    }

    formik.setFieldValue("contas", newRows);
  };

  const checkIfHaveRs = (valor) => {
    if (typeof valor !== "string") {
      return valor;
    }

    if (valor.includes("R$")) {
      valor = valor.replace("R$", "").trim();
    }
    valor = commaToDot(valor);

    return valor;
  };

  useEffect(() => {
    setRows(
      formik.values?.contas?.map((conta) => ({
        ...conta,
        action: (
          <div
            style={{
              justifyContent: "center",
              display: isDetalhar ? "none" : "flex",
            }}
          >
            <Tooltip title="Editar">
              <MDButton
                variant="contained"
                color="info"
                type="button"
                sx={{ marginRight: 1 }}
                onClick={() => {
                  handleEdit(conta);
                }}
                iconOnly
              >
                <Icon fontSize="small">edit</Icon>
              </MDButton>
            </Tooltip>

            <Tooltip title="Remover">
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                type="submit"
                onClick={() => handleDelete(conta)}
                iconOnly
              >
                <Icon fontSize="small">delete_forever</Icon>
              </MDButton>
            </Tooltip>
          </div>
        ),
        valor: Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(checkIfHaveRs(conta.valor)),
      }))
    );
  }, [formik.values.contas]);

  return (
    <>
      {showAddState && (
        <AdicionarConta
          setShowAddState={setShowAddState}
          handleAdd={handleAdd}
          isReceber={isReceber}
          rows={rows}
          cliente={cliente}
          valorTotal={valorTotal}
          parentDataPedido={formik.values.dataDescarga || null}
          dataPedido={dataPedido}
        />
      )}

      {showEditStateNew && (
        <AdicionarConta
          setShowAddState={setShowEditStateNew}
          handleAdd={handleAdd}
          isReceber={isReceber}
          rows={rows}
          cliente={cliente}
          valorTotal={valorTotal}
          currEdit={currentEditNew}
          handleEditCurrent={handleEditCurrent}
          dataPedido={dataPedido}
        />
      )}

      {showEditStateCurr && isReceber && (
        <EditarDadoReceber
          setShowEditState={setShowEditStateCurr}
          currentIdAction={currentIdAction}
          fetchData={refreshParent}
          fecharOnSave
          dataPedido={dataPedido}
        />
      )}

      {showEditStateCurr && !isReceber && (
        <EditarDadoPagar
          setShowEditState={setShowEditStateCurr}
          currentIdAction={currentIdAction}
          fetchData={refreshParent}
          fecharOnSave
          dataPedido={dataPedido}
        />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!isDetalhar && (
          <MDButton
            variant="contained"
            color="info"
            type="button"
            onClick={() => {
              scrollToTop();
              setShowAddState(true);
            }}
          >
            Adicionar Item
          </MDButton>
        )}
      </div>

      {rows?.length > 0 && (
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{
            defaultValue: 25,
          }}
          noEndBorder
        />
      )}

      {rows?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
          }}
        >
          Nenhuma conta adicionada
        </div>
      )}
    </>
  );
}
