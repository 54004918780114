import { Box, Button, Grid, Card, TextField } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useState } from "react";
import Service from "services/payables";
import AccountSubgroupService from "services/sub-group-accounts";
import AccountGroupsService from "services/account-groups";
import PaymentMethodService from "services/payment-methods";
import SupplierService from "services/suppliers";
import AccountService from "services/accounts";
import {
  yupSchema,
  initialValues,
  BILLING_STATUS,
  findBillingStatus,
} from "./yupSchema";
import { useEffect } from "react";
import CurrencyInput from "react-currency-input-field";

export default function AdicionarContaAPagar({ setShowAddState, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [dataList, setDataList] = useState({
    accountSubgroup: [],
    accountGroups: [],
    paymentMethods: [],
    suppliers: [],
    accounts: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: accountGroups } = await AccountGroupsService.list();
        const { data: accountSubgroup } = await AccountSubgroupService.list();
        const { data: paymentMethods } = await PaymentMethodService.list();
        const { data: suppliers } = await SupplierService.list();
        const { data: accounts } = await AccountService.list();

        setDataList({
          accountSubgroup,
          accountGroups,
          paymentMethods,
          suppliers,
          accounts,
        });
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      if (
        formik.values.dataPagamento?.toString().length > 0 &&
        (formik.values.account?.toString().length === 0 ||
          formik.values.account === null)
      ) {
        alert("Favor informar a conta!");
        setLoading(false);
        return;
      }

      try {
        await Service.create(values);
        formik.resetForm();
        setSuccess(true);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        formik.setFieldValue(name, `${removeDots},00`);
      }
    }
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowAddState(false);
        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Cadastrar Conta a pagar
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="partidaContabil"
                    name="partidaContabil"
                    label="Partida Contábil"
                    type="number"
                    value={formik.values.partidaContabil}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.partidaContabil &&
                      Boolean(formik.errors.partidaContabil)
                    }
                    helperText={
                      formik.touched.partidaContabil &&
                      formik.errors.partidaContabil
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.descricao &&
                      Boolean(formik.errors.descricao)
                    }
                    helperText={
                      formik.touched.descricao && formik.errors.descricao
                    }
                  />

                  <TextField
                    id="supplier"
                    name="supplier"
                    label="Fornecedor"
                    value={formik.values.supplier}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.supplier && Boolean(formik.errors.supplier)
                    }
                    helperText={
                      formik.touched.supplier && formik.errors.supplier
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="" />
                    {dataList.suppliers
                      .filter((option) => option.ativo == true)
                      .map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.razaoSocial || option.nomeFantasia}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Método de Pagamento"
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paymentMethod &&
                      Boolean(formik.errors.paymentMethod)
                    }
                    helperText={
                      formik.touched.paymentMethod &&
                      formik.errors.paymentMethod
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="" />
                    {dataList.paymentMethods.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    id="dataPedido"
                    name="dataPedido"
                    label="Data do Pedido"
                    type="date"
                    value={formik.values.dataPedido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataPedido &&
                      Boolean(formik.errors.dataPedido)
                    }
                    helperText={
                      formik.touched.dataPedido && formik.errors.dataPedido
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="dataVencimento"
                    name="dataVencimento"
                    label="Data de Vencimento"
                    type="date"
                    value={formik.values.dataVencimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataVencimento &&
                      Boolean(formik.errors.dataVencimento)
                    }
                    helperText={
                      formik.touched.dataVencimento &&
                      formik.errors.dataVencimento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="dataPagamento"
                    name="dataPagamento"
                    label="Data de Pagamento"
                    type="date"
                    value={formik.values.dataPagamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataPagamento &&
                      Boolean(formik.errors.dataPagamento)
                    }
                    helperText={
                      formik.touched.dataPagamento &&
                      formik.errors.dataPagamento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <CurrencyInput
                    id="valor"
                    name="valor"
                    label="Valor do Título"
                    value={formik.values.valor}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    sx={{
                      width: "100%",
                    }}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  />

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    {Object.values(BILLING_STATUS).map((status) => (
                      <option key={status} value={status}>
                        {findBillingStatus(status)}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="account"
                    name="account"
                    label="Conta"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.account && Boolean(formik.errors.account)
                    }
                    helperText={formik.touched.account && formik.errors.account}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList.accounts.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountGroup"
                    name="accountGroup"
                    label="Centro de Custo"
                    value={formik.values.accountGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountGroup &&
                      Boolean(formik.errors.accountGroup)
                    }
                    helperText={
                      formik.touched.accountGroup && formik.errors.accountGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList.accountGroups.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountSubGroup"
                    name="accountSubGroup"
                    label="Natureza de Lançamento"
                    value={formik.values.accountSubGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountSubGroup &&
                      Boolean(formik.errors.accountSubGroup)
                    }
                    helperText={
                      formik.touched.accountSubGroup &&
                      formik.errors.accountSubGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="" />
                    {dataList.accountSubgroup.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="observacoes"
                    name="observacoes"
                    label="Observação"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacoes &&
                      Boolean(formik.errors.observacoes)
                    }
                    helperText={
                      formik.touched.observacoes && formik.errors.observacoes
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Conta a pagar cadastrada com sucesso!"
                          : "Ocorreu um erro ao cadastrar a conta a pagar."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
