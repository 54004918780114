import { Icon, TextField, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { commaToDot } from "utils/numberComma";
import AcusarRecebimento from "./acusarRecebimento";
import VisualizarRecebimentos from "./visualizarRecebimentos";

const QTD = ({ formik, produto }) => {
  const [qtd, setQtd] = useState(produto?.qtd);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          qtd,
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [qtd]);

  const plus = () => {
    setQtd(Number(qtd) + 1);
  };

  const minus = () => {
    if (qtd > 0) {
      setQtd(Number(qtd) - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
          margin: "0 5px",
        },
      }}
    >
      <Icon
        style={{ color: "#81BF52", cursor: "pointer" }}
        fontSize="small"
        onClick={minus}
      >
        remove
      </Icon>

      <TextField
        type="number"
        min={0}
        value={qtd}
        onChange={(e) => setQtd(Number(e.target.value))}
        onInput={(e) => {
          if (e.target.value.split(".")[1]?.length > 2) {
            e.target.value = parseFloat(e.target.value).toFixed(2);
          }
        }}
        sx={{
          mx: "5px",
        }}
      />

      <Icon
        style={{ color: "#81BF52", cursor: "pointer" }}
        fontSize="small"
        onClick={plus}
      >
        add
      </Icon>
    </div>
  );
};

const ValorUnitario = ({ formik, produto, custoUnitario }) => {
  const [valorUnitario, setValorUnitario] = useState(produto?.valorUnitario);

  useEffect(() => {
    const produtos = formik.values?.produtos?.map((item) => {
      if (item?._id === produto?._id) {
        return {
          ...item,
          valorUnitario: commaToDot(valorUnitario),
        };
      }
      return item;
    });

    formik.setFieldValue("produtos", produtos);
  }, [valorUnitario]);

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removePrefix = value
        .replace("R$", "")
        .replace("US$", "")
        .replace("€", "")
        .trim();
      const removeDots = removePrefix.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        setValorUnitario(`${removeDots},00`);
      }
    }
  };

  return (
    <CurrencyInput
      id="valorUnitario"
      name="valorUnitario"
      label="Valor Unitário"
      value={valorUnitario}
      onBlur={formik.handleBlur}
      decimalsLimit={2}
      onValueChange={(val) => {
        if (custoUnitario && custoUnitario > 0) {
          const valorAtualComparar = commaToDot(val);

          if (valorAtualComparar < custoUnitario) {
            formik.setFieldError(
              "valorUnitario",
              "Valor unitário não pode ser menor ou igual ao custo unitário!"
            );
          }
        }

        changeCurrency(val, "valorUnitario");
        setValorUnitario(val);
      }}
      onBlurCapture={addZero}
      customInput={TextField}
      error={
        formik.touched.valorUnitario && Boolean(formik.errors.valorUnitario)
      }
      helperText={formik.touched.valorUnitario && formik.errors.valorUnitario}
      sx={{
        width: "100%",
      }}
      intlConfig={{
        locale: "pt-BR",
        currency: formik.values?.currency || "BRL",
      }}
    />
  );
};

export default function Itens({
  formik,
  refAddItem,
  isAdd = false,
  isDetalhar = false,
  setShowAddProduto = () => {},
  setCurrentItem = () => {},
  currentItem = null,
  custoUnitario = null,
  acusandoRecebimento,
  setAcusandoRecebimento,
  visualizandoRecebimentos,
  setVisualizandoRecebimentos,
  contas = [],
}) {
  const [rows, setRows] = useState([]);

  const columns = [
    {
      Header: "QTD",
      accessor: "qtd",
      align: "left",
    },
    {
      Header: "Código",
      accessor: "codigoInterno",
      align: "left",
    },
    {
      Header: "Produto",
      accessor: "produto",
      align: "left",
    },
    {
      Header: "Unidade de Medida",
      accessor: "unidadeMedida",
      align: "left",
    },
    {
      Header: "Valor Unitário",
      accessor: "valorUnitario",
    },
    {
      Header: "QTD Recebida",
      accessor: "qtdRecebida",
    },
    {
      Header: "Total Item",
      accessor: "totalItem",
    },
    {
      Header: "Recebido",
      accessor: "valorRecebido",
    },
    { Header: isDetalhar ? "" : "Ação", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    setRows(
      formik.values?.produtos?.map((produto, index) => ({
        codigoInterno: produto?.codigoInterno,
        qtd: isDetalhar ? (
          produto.qtd
        ) : (
          <QTD formik={formik} produto={produto} />
        ),
        unidadeMedida: produto?.unidadeMedida,
        produto: produto?.produto,
        valorUnitario: isDetalhar ? (
          produto.valorUnitario?.toLocaleString("pt-BR", {
            style: "currency",
            currency: formik.values?.currency || "BRL",
          })
        ) : (
          <ValorUnitario
            formik={formik}
            produto={produto}
            custoUnitario={custoUnitario}
          />
        ),
        qtdRecebida: produto?.qtdRecebida,
        totalItem: (produto?.qtd * produto?.valorUnitario)?.toLocaleString(
          "pt-BR",
          { style: "currency", currency: formik.values?.currency || "BRL" }
        ),
        valorRecebido: produto?.valorRecebido?.toLocaleString("pt-BR", {
          style: "currency",
          currency: formik.values?.currency || "BRL",
        }),
        action: (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              gap: "5px",
            }}
          >
            <Tooltip
              title="Remover"
              style={{
                display:
                  isDetalhar || formik?.values?.produtos[index].qtdRecebida > 0
                    ? "none"
                    : "flex",
              }}
            >
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                onClick={() => handleDelete(produto)}
                iconOnly
              >
                <Icon fontSize="small">delete_forever</Icon>
              </MDButton>
            </Tooltip>
            {produto?.qtdRecebida < produto?.qtd && (
              <Tooltip
                title="Acusar Recebimento"
                style={{ display: isDetalhar || isAdd ? "none" : "flex" }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  iconOnly
                  onClick={() => setAcusandoRecebimento(produto)}
                >
                  <Icon fontSize="small">archive</Icon>
                </MDButton>
              </Tooltip>
            )}
            <Tooltip
              title="Visualizar Recebimentos"
              disabled={isAdd}
              style={{ display: isAdd ? "none" : "flex" }}
            >
              <MDButton
                variant="gradient"
                color="success"
                fullWidth
                iconOnly
                onClick={() => setVisualizandoRecebimentos(produto)}
              >
                <Icon fontSize="small">visibility</Icon>
              </MDButton>
            </Tooltip>
          </div>
        ),
      }))
    );
  }, [formik.values]);

  const handleDelete = (produto) => {
    const produtos = formik.values?.produtos?.filter(
      (item) => item?._id !== produto?._id
    );

    formik.setFieldValue("produtos", produtos);
  };

  const handleAdd = (produto) => {
    // if product already exists, just increment the quantity
    const productExists = formik.values?.produtos?.find(
      (item) => item?._id === produto?._id
    );

    if (productExists) {
      const produtos = formik.values?.produtos?.map((item) => {
        if (item?._id === produto?._id) {
          return {
            ...item,
            qtd: item?.qtd + 1,
          };
        }
        return item;
      });

      formik.setFieldValue("produtos", produtos);
      setShowAddProduto(false);
      setRows(produtos);
    } else {
      const produtos = [
        ...formik.values?.produtos,
        {
          _id: produto?._id,
          codigoInterno: produto?.codigoInterno,
          qtd: 1,
          produto: produto?.descricao,
          valorUnitario: produto?.precoVenda,
          unidadeMedida: produto?.unidade,
          qtdRecebida: 0,
          valorRecebido: 0,
        },
      ];
      setShowAddProduto(false);
      formik.setFieldValue("produtos", produtos);
      setRows(produtos);
    }
  };

  const scrollToTop = () => {
    refAddItem?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (currentItem) {
      handleAdd(currentItem);
      setCurrentItem(null);
    }
  }, [currentItem]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        {!isDetalhar && !process.env.REACT_APP_IS_PRIME_PLUS && (
          <MDButton
            variant="contained"
            color="info"
            type="button"
            onClick={() => {
              scrollToTop();
              setShowAddProduto(true);
            }}
          >
            Adicionar Item
          </MDButton>
        )}
      </div>
      {rows?.length > 0 && (
        <div style={{ marginTop: "-40px" }}>
          <DataTable
            table={{ columns, rows }}
            isSorted={false}
            disablePagination={true}
            noEndBorder
          />
        </div>
      )}
      {rows?.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
          }}
        >
          Nenhum item adicionado
        </div>
      )}
      {/* Acusar recebimento */}
      {acusandoRecebimento && (
        <AcusarRecebimento
          setShowAddState={setAcusandoRecebimento}
          parentFormik={formik}
          produto={acusandoRecebimento}
          contas={contas}
        />
      )}
      {visualizandoRecebimentos && (
        <VisualizarRecebimentos
          setShowAddState={setVisualizandoRecebimentos}
          parentFormik={formik}
          produto={visualizandoRecebimentos}
          isDetalhar={isDetalhar}
        />
      )}
    </>
  );
}
