import HttpService from "./http.service";

class StockTransactionsService {
  list = async (sortStr = null, filterStr = null) => {
    const stockTransactionsEndpoint = `/stock-transactions?limit=-1${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(stockTransactionsEndpoint);
  };

  paginate = async (
    warehouseId,
    page = 0,
    limit = 25,
    sortStr = null,
    filterStr = null
  ) => {
    const stockTransactionsEndpoint = `/stock-transactions/warehouse/${warehouseId}?page=${page}&limit=${limit}${
      sortStr ? sortStr : ""
    }${filterStr ? filterStr : ""}`;
    return await HttpService.get(stockTransactionsEndpoint);
  };

  create = async (data) => {
    const stockTransactionsEndpoint = "/stock-transactions";
    return await HttpService.post(stockTransactionsEndpoint, data);
  };

  getReport = async (data) => {
    const stockTransactionsEndpoint = "/stock-transactions/report";
    return await HttpService.post(stockTransactionsEndpoint, data);
  };
}

export default new StockTransactionsService();
