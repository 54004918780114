import { Alert, Box, Button, Card, Grid, Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { format } from "utils/formatDate";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useFormik } from "formik";
import {
  STATUS_PEDIDO,
  findStatusPedido,
} from "layouts/pedidos-venda/components/yupSchema";
import { useEffect, useState } from "react";
import { exportToExcel } from "react-json-to-excel";
import Service from "services/payables";
import AdicionarFornecedor from "layouts/fornecedores";
import { dotToComma } from "utils/numberComma";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { ptBR_DataGrid } from "utils/datagrid";
import MDButton from "components/MDButton";
import { TIPO_FRETE } from "layouts/pedidos-compra/components/yupSchema";

const initialValues = {
  startDate: "",
  endDate: "",
  supplier: null,
  status: "",
};

export default function AcertoFornecedor() {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [originalReport, setOriginalReport] = useState([]);

  const [showAddFornecedor, setShowAddFornecedor] = useState(false);
  const [childData, setChildData] = useState({});

  const [totalListado, setTotalListado] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);

  const widths = [110, 60, 140, 60, 60, 60, 60, 60, 140, 140, 110, 130];

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const rows = await Service.agreementWithSuppliers({
          ...values,
          supplier: values.supplier?._id || values.supplier,
        });

        const rowsSemLetter = rows.filter(
          (row) =>
            row.sale.letterPedido === "" ||
            row.sale.letterPedido === undefined ||
            row.sale.letterPedido === null
        );

        setTotalListado(
          rowsSemLetter.reduce(
            (acc, row) => acc + Number(row.purchase?.valorTotal),
            0
          )
        );

        setTotalReceber(
          rowsSemLetter.reduce(
            (acc, row) =>
              acc +
              (Number(row.sale?.valorTotal) -
                Number(row.sale?.totalPagarFrete || 0)),
            0
          )
        );

        setOriginalReport(rows);
        const agreements = await parseAgreements(rowsSemLetter);

        if (!agreements.length) {
          alert("Nenhum resultado encontrado");
        }

        setReport(agreements);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    },
  });

  const parseAgreements = async (rows) => {
    if (!rows || rows.length === 0) return [];
    const data = [];

    const mapaPedidoJaExibiu = new Map();

    for (const row of rows) {
      if (row.sale?.valorTotal === 0 || !row.sale?.valorTotal) {
        row.sale.valorTotal = row.sale?.products?.reduce((acc, product) => {
          return acc + Number(product.price) * Number(product.quantity);
        }, 0);
      }

      if (row.sale?.valorTotal > 0) {
        if (row.sale?.valorDescarga && row.sale?.valorDescarga > 0) {
          row.sale.valorTotal -= row.sale.valorDescarga;
        }

        if (row.sale?.totalPagarFrete && row.sale?.totalPagarFrete > 0) {
          if (
            row.sale?.tipoFrete === TIPO_FRETE.PRIME ||
            row.sale?.tipoFrete === TIPO_FRETE.FOB
          ) {
            row.sale.valorTotal -= row.sale.totalPagarFrete;
          }
        }
      }

      if (row.purchase?.valorTotal === 0 || !row.purchase?.valorTotal) {
        row.purchase.valorTotal = row.purchase?.products?.reduce(
          (acc, product) => {
            return acc + Number(product.price) * Number(product.quantity);
          },
          0
        );
      }

      const products = row.purchase?.products || [];
      const saleProducts = row.sale?.products || [];

      const totalQtdProdutos = products.reduce(
        (acc, product) => acc + product.quantity,
        0
      );

      for (let i = 0; i < Math.max(products.length, saleProducts.length); i++) {
        const purchaseProduct = products[i] || {};
        const saleProduct = saleProducts[i] || {};

        const jaExibiu = mapaPedidoJaExibiu.get(row.purchase?.numPedido);

        const pesoLiquido =
          products.length > 1
            ? purchaseProduct?.quantity
            : row.purchase?.pesoLiquido;

        const pesoLiquidoComDescarte =
          products.length > 1
            ? calculaPesoProdutoComDescarte(
                purchaseProduct?.quantity,
                totalQtdProdutos
              )
            : calculaPesoComDescarte(row.purchase?.pesoLiquido);

        data.push({
          Emissão: format(new Date(row.purchase?.data), "dd/MM/yyyy"),
          Pedido: row.purchase?.numPedido + row.sale?.letterPedido,
          "Descrição do Produto":
            purchaseProduct?.product?.descricao ||
            saleProduct?.product?.descricao ||
            "",
          Média: jaExibiu
            ? "-"
            : dotToComma(Number(row.purchase.media).toFixed(2)),
          "Vlr. Unit.": dotToComma(
            Number(purchaseProduct?.price || 0).toFixed(2)
          ),
          "Peso Líquido": Number(pesoLiquido),
          "Peso Líquido à Pagar": Number(pesoLiquidoComDescarte),
          "Vlr. Total": dotToComma(
            (
              Number(purchaseProduct?.price || 0) * Number(pesoLiquido || 0)
            ).toFixed(2)
          ),
          "Vlr. Com Descarte à Pagar": dotToComma(
            (
              Number(purchaseProduct?.price || 0) *
              Number(pesoLiquidoComDescarte || 0)
            ).toFixed(2)
          ),
          Turma: jaExibiu
            ? "-"
            : dotToComma(Number(row.purchase?.carregamento).toFixed(2)),
          Transportadora: jaExibiu
            ? "-"
            : row.purchase?.shippingCompany?.nomeFantasia ||
              row.purchase?.shippingCompany?.razaoSocial ||
              row.purchase?.motorista,
          Veículo: jaExibiu ? "-" : row.purchase?.caminhao,
          Status: jaExibiu ? "-" : findStatusPedido(row.sale?.status) || "",
          "Total à Receber": jaExibiu
            ? "-"
            : row.sale?.valorTotal
            ? dotToComma(Number(Number(row.sale?.valorTotal)).toFixed(2))
            : "",
        });

        if (!jaExibiu) {
          mapaPedidoJaExibiu.set(row.purchase?.numPedido, true);
        }
      }
    }

    return data;
  };

  const calculaPesoComDescarte = (pesoReal) => {
    let descarte = pesoReal * 0.02;

    if (pesoReal < 18000) {
      descarte += 1000;
    } else if (pesoReal >= 18000 && pesoReal < 26000) {
      descarte += 1500;
    } else {
      descarte += 2000;
    }

    return pesoReal - descarte;
  };

  const calculaPesoProdutoComDescarte = (pesoReal, totalQtdProdutos) => {
    let descarte = pesoReal * 0.02;

    if (totalQtdProdutos < 18000) {
      descarte += (1000 / totalQtdProdutos) * pesoReal;
      console.log(descarte, "descarte 1000");
    } else if (totalQtdProdutos >= 18000 && totalQtdProdutos < 26000) {
      descarte += (1500 / totalQtdProdutos) * pesoReal;
      console.log(descarte, "descarte 1500");
    } else {
      descarte += (2000 / totalQtdProdutos) * pesoReal;
      console.log(descarte, "descarte 2000");
    }

    return pesoReal - descarte;
  };

  const descobreTurma = (pesoLiquido) => {
    if (pesoLiquido < 17000) {
      return 400;
    } else if (pesoLiquido >= 17000 && pesoLiquido < 22000) {
      return 600;
    } else {
      return 900;
    }
  };

  const printAgreements = (agreements) => {
    exportToExcel(agreements, "acerto_" + new Date().getTime(), true);
  };

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddFornecedor) setShowAddFornecedor(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddFornecedor]);

  const EditField = ({ value }) => {
    return (
      <TextField
        // id={fieldName}
        // name={fieldName}
        value={value}
        // onChange={onChange}
        disabled={loading}
        sx={{
          width: "100%",
        }}
      />
    );
  };

  return (
    <DashboardLayout>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Relatório de Acerto com Fornecedor
                </MDTypography>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="startDate"
                    name="startDate"
                    label="Data Inicial"
                    type="date"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    id="endDate"
                    name="endDate"
                    label="Data Final"
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    sx={{
                      width: "100%",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        onClick={() => setShowAddFornecedor(true)}
                      >
                        <Icon fontSize="small">edit</Icon>
                      </MDButton>
                    }
                  >
                    <b>Fornecedor: </b>
                    {childData.supplier?.razaoSocial ||
                      childData.supplier?.nomeFantasia}
                  </Alert>

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                    sx={{
                      width: "100%",
                    }}
                    select
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option aria-label="Selecione" value="" />

                    {Object.values(STATUS_PEDIDO).map((opcao) => (
                      <option key={opcao} value={opcao}>
                        {findStatusPedido(opcao)}
                      </option>
                    ))}
                  </TextField>

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Gerar Relatório"}
                  </Button>
                </Box>
              </MDBox>
            </Card>

            {report.length > 0 && (
              <>
                <Card sx={{ marginTop: 4 }}>
                  <DataGrid
                    initialState={{
                      pagination: { paginationModel: { pageSize: 100 } },
                    }}
                    pageSizeOptions={[100, 200]}
                    rows={report.map((row, index) =>
                      Object({ ...row, id: index })
                    )}
                    columns={Object.keys(report[0]).map((key, idx) =>
                      Object({
                        headerName: key,
                        field: key,
                        flex: 1,
                        idx: idx,
                        minWidth: widths[idx],
                      })
                    )}
                    rowCount={report.length}
                    rowSelection={false}
                    localeText={ptBR_DataGrid}
                    sx={{
                      ".MuiDataGrid-virtualScroller": {
                        minHeight: "100px",
                      },
                    }}
                    disableColumnFilter
                    headerFilters
                    slots={{
                      headerFilterMenu: null,
                    }}
                  />
                </Card>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      printAgreements([
                        {
                          sheetName: "Acerto Fornecedor",
                          details: report,
                        },
                      ])
                    }
                    sx={{
                      color: "white !important",
                    }}
                  >
                    Exportar para Excel
                  </Button>
                </Box>
              </>
            )}

            {(totalListado > 0 || totalReceber > 0) && (
              <Card sx={{ marginTop: 4 }}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MDTypography variant="h6" color="white" mt={1}>
                    Totais
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} mx={2} py={3} px={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" color="info">
                        Total Listado
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" color="info">
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(totalListado)}
                      </MDTypography>
                    </Grid>

                    <Grid item xs={6}>
                      <MDTypography variant="h6" color="info">
                        Total à Receber
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" color="info">
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(totalReceber)}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            )}
          </Grid>
        </Grid>
      </MDBox>

      {showAddFornecedor && (
        <MDBox
          pt={"30px"}
          pb={"5%"}
          px={"5%"}
          sx={{
            position: "absolute",
            zIndex: 9999,
            width: "100vw",
            minHeight: "100vh",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
            overflow: "auto",
          }}
        >
          <AdicionarFornecedor
            fromParent={true}
            selectParent={(value) => {
              setChildData((prevState) =>
                Object({ ...prevState, supplier: value })
              );
              selectParent("supplier", value?._id);
            }}
            setShowAddFornecedor={setShowAddFornecedor}
          />
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}
