import { Box, Grid, Card, TextField, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/payables";
import BatchOfSettlementsService from "services/batchOfSettlements";
import AccountSubgroupService from "services/sub-group-accounts";
import AccountGroupsService from "services/account-groups";
import {
  yupSchema,
  initialValues,
  BILLING_STATUS,
  findBillingStatus,
} from "./yupSchema";
import CurrencyInput from "react-currency-input-field";
import { format } from "utils/formatDate";

export default function DetalharContaAPagar({
  setShowDetailState,
  currentIdAction,
  fetchData,
}) {
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);
      const batch = await BatchOfSettlementsService.getByPayable(
        currentIdAction
      );
      setBatches(batch);
      const valorPago = batch.reduce((batchAcc, currentBatch) => {
        return (
          batchAcc +
          currentBatch.payables
            .filter((item) => item.payable_id === currentIdAction)
            .reduce((acc, item) => acc + item.valor, 0)
        );
      }, 0);
      formik.setFieldValue("valorAberto", valorPago - fields.valor);
      const accountGroups = await AccountGroupsService.get(fields.accountGroup);
      const accountSubgroups = await AccountSubgroupService.get(
        fields.accountSubGroup
      );

      fields.accountGroup = accountGroups.descricao;
      fields.accountSubGroup = accountSubgroups.nome;

      for (var key in formik.values) {
        if (key.includes("data"))
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        else if (
          key === "accountGroup" ||
          key === "accountSubGroup" ||
          key === "account" ||
          key === "paymentMethod" ||
          key === "supplier"
        ) {
          if (fields[key] !== undefined && fields[key] !== null) {
            formik.setFieldValue(
              key,
              fields[key].razaoSocial ||
                fields[key].nomeFantasia ||
                fields[key].nome ||
                fields[key].descricao ||
                fields[key]._id ||
                fields[key]
            );
          }
        } else formik.setFieldValue(key, fields[key]);
      }
      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        formik.setFieldValue(name, `${removeDots},00`);
      }
    }
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowDetailState(false);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "auto",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Detalhes
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setShowDetailState(false);
                  }}
                >
                  Fechar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="partidaContabil"
                    name="partidaContabil"
                    label="Partida Contábil"
                    type="number"
                    value={formik.values.partidaContabil}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.partidaContabil &&
                      Boolean(formik.errors.partidaContabil)
                    }
                    helperText={
                      formik.touched.partidaContabil &&
                      formik.errors.partidaContabil
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.descricao &&
                      Boolean(formik.errors.descricao)
                    }
                    helperText={
                      formik.touched.descricao && formik.errors.descricao
                    }
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="supplier"
                    name="supplier"
                    label="Fornecedor"
                    value={formik.values.supplier}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Método de Pagamento"
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="dataPedido"
                    name="dataPedido"
                    label="Data do Pedido"
                    type="date"
                    value={formik.values.dataPedido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="dataVencimento"
                    name="dataVencimento"
                    label="Data de Vencimento"
                    type="date"
                    value={formik.values.dataVencimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="dataPagamento"
                    name="dataPagamento"
                    label="Data de Pagamento"
                    type="date"
                    value={formik.values.dataPagamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <CurrencyInput
                    id="valor"
                    name="valor"
                    label="Valor do Título"
                    value={formik.values.valor}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    sx={{
                      width: "100%",
                    }}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <CurrencyInput
                    id="valorAberto"
                    name="valorAberto"
                    label="Valor em Aberto"
                    value={formik.values.valorAberto}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={
                      formik.touched.valorAberto &&
                      Boolean(formik.errors.valorAberto)
                    }
                    helperText={
                      formik.touched.valorAberto && formik.errors.valorAberto
                    }
                    sx={{
                      width: "100%",
                    }}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={findBillingStatus(formik.values.status)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="account"
                    name="account"
                    label="Conta"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="accountGroup"
                    name="accountGroup"
                    label="Centro de Custo"
                    value={formik.values.accountGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="accountSubGroup"
                    name="accountSubGroup"
                    label="Natureza de Lançamento"
                    value={formik.values.accountSubGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="observacoes"
                    name="observacoes"
                    label="Observações"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />

                  {batches.length > 0 && (
                    <MDBox
                      sx={{
                        gridColumn: "span 2",
                        justifySelf: "center",
                        width: "100%",
                      }}
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        sx={{
                          width: "100%",
                          color: "white !important",
                          cursor: "pointer",
                        }}
                        readOnly
                      >
                        <Icon fontSize="small" sx={{ mr: 1 }}>
                          inbox
                        </Icon>
                        Lotes de baixa em que é mencionado
                      </MDButton>

                      {batches.map((batch) => (
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginTop: "10px",
                            backgroundColor: "#fff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                          }}
                        >
                          <thead>
                            <tr
                              style={{
                                backgroundColor: "#f5f5f5",
                                borderBottom: "2px solid #ddd",
                              }}
                            >
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Lote
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Descrição
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Conta de Origem
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Data do Pagamento
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "right" }}
                              >
                                Valor da Baixa
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ borderBottom: "1px solid #ddd" }}>
                              <td style={{ padding: "12px" }}>{batch.nome}</td>
                              <td style={{ padding: "12px" }}>
                                {batch.descricao}
                              </td>
                              <td style={{ padding: "12px" }}>
                                {batch.account.nome}
                              </td>
                              <td style={{ padding: "12px" }}>
                                {(batch.payables.find(
                                  (item) => item.payable_id === currentIdAction
                                )?.dataBaixa ||
                                  batch.dataPagamento) &&
                                  new Intl.DateTimeFormat("pt-BR").format(
                                    new Date(
                                      batch.payables.find(
                                        (item) =>
                                          item.payable_id === currentIdAction
                                      )?.dataBaixa || batch.dataPagamento
                                    )
                                  )}
                              </td>
                              <td
                                style={{ padding: "12px", textAlign: "right" }}
                              >
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(
                                  batch.payables.find(
                                    (item) =>
                                      item.payable_id === currentIdAction
                                  )?.valor || 0
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </MDBox>
                  )}
                </Box>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
