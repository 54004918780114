import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Service from "services/purchasesWarehouse";
import {
  MOEDAS,
  STATUS_PEDIDO,
  findStatusPedido,
  initialValues,
  yupSchema,
} from "./yupSchema";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// @mui icons
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

// forms
import MDButton from "components/MDButton";
import { format } from "utils/formatDate";
import { commaToDot, dotToComma } from "utils/numberComma";
import Contas from "./contas";
import Itens from "./itens";

// forms alternativos
import AdicionarFornecedor from "layouts/fornecedores";
import AdicionarFuncionario from "layouts/funcionarios";
import AdicionarFilial from "layouts/grupos-contas";
import AdicionarProduto from "layouts/produtos";

//@mui/styles
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#81BF52",
    },
    "& .MuiTab-root": {
      color: "#425d73 !important",
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: "#81bf52ab",
      color: "#FFFFFF !important",
    },
  },
});

export default function AdicionarPedidoCompra({ setShowAddState, fetchData }) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);

  // forms alternativos
  const [showAddProduto, setShowAddProduto] = useState(false);
  const [showAddFornecedor, setShowAddFornecedor] = useState(false);
  const [showAddFuncionario, setShowAddFuncionario] = useState(false);
  const [showAddFilial, setShowAddFilial] = useState(false);

  const [currentItem, setCurrentItem] = useState(null);

  // tabs
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  // erro produto
  const [erroProduto, setErroProduto] = useState(false);

  const [childData, setChildData] = useState({});

  const classes = useStyles();

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setSuccess(null);
      setErroProduto(false);

      try {
        if (values.produtos.length === 0) {
          setErroProduto(true);
          throw new Error("Erro ao salvar produto");
        }

        const request = {
          ...values,
          supplier: values.supplier?._id || values.supplier,
          user: values.user?._id || values.user,
          filial: values.filial?._id || values.filial,
        };
        await Service.create(request);
        formik.resetForm();
        setSuccess(true);
        setChildData({});
        fetchData();
        setShowAddState(false);
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }

      setAlertKey(alertKey + 1);

      setLoading(false);
    },
  });

  const totalValorProdutos = () => {
    let total = 0;
    let valorTotal = 0;

    formik.values.produtos?.forEach((produto) => {
      total += produto.valorUnitario * produto.qtd;
      valorTotal += produto.valorUnitario * produto.qtd;
    });

    formik.setFieldValue(
      "valorTotal",
      isNaN(valorTotal) ? 0 : dotToComma(valorTotal.toFixed(2))
    );

    return total;
  };

  useEffect(() => {
    totalValorProdutos();
  }, [formik.values.produtos]);

  useEffect(() => {
    formik.setFieldValue("data", format(new Date(), "yyyy-MM-dd"));
  }, []);

  const selectParent = (parent, value) => {
    formik.setFieldValue(parent, value);
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        if (showAddProduto) setShowAddProduto(false);
        if (showAddFornecedor) setShowAddFornecedor(false);
        if (showAddFuncionario) setShowAddFuncionario(false);
        if (showAddFilial) setShowAddFilial(false);

        if (
          !showAddProduto &&
          !showAddFornecedor &&
          !showAddFuncionario &&
          !showAddFilial
        ) {
          setShowAddState(false);
          fetchData();
        }
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, [showAddProduto, showAddFornecedor, showAddFuncionario, showAddFilial]);

  return (
    <>
      <MDBox
        pt={"30px"}
        pb={"5%"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          backdropFilter: "blur(5px)",
          top: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={11} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  {loading ? "Carregando..." : "Cadastrar Pedido de Compra"}
                </MDTypography>

                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowAddState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>

              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    mb: 2,
                    // display: loading ? "none" : "",
                  }}
                >
                  <Grid
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      id="numPedido"
                      name="numPedido"
                      label="Número do Pedido"
                      value={formik.values.numPedido}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                      error={
                        formik.touched.numPedido &&
                        Boolean(formik.errors.numPedido)
                      }
                      helperText={
                        formik.touched.numPedido && formik.errors.numPedido
                      }
                      sx={{
                        width: "100%",
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />

                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddFornecedor(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                      sx={{
                        border: formik.errors.supplier ? "1px solid red" : "",
                        paddingBlock: 0,
                        height: "fit-content",
                      }}
                    >
                      <b>Fornecedor: </b>
                      {childData.supplier?.razaoSocial ||
                        childData.supplier?.nomeFantasia}
                    </Alert>

                    <TextField
                      id="currency"
                      name="currency"
                      label="Selecione a Moeda"
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                      error={
                        formik.touched.currency &&
                        Boolean(formik.errors.currency)
                      }
                      helperText={
                        formik.touched.currency && formik.errors.currency
                      }
                      sx={{
                        width: "100%",
                      }}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {Array.from(Object.entries(MOEDAS)).map(
                        ([key, value]) => (
                          <option key={key} value={key}>
                            {value[1]}
                          </option>
                        )
                      )}
                    </TextField>

                    <TextField
                      id="contato"
                      name="contato"
                      label="Contato"
                      value={formik.values.contato}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={
                        formik.touched.contato && Boolean(formik.errors.contato)
                      }
                      helperText={
                        formik.touched.contato && formik.errors.contato
                      }
                      sx={{
                        width: "100%",
                      }}
                    />

                    <TextField
                      id="dataLancamento"
                      name="dataLancamento"
                      label="Data de Lançamento"
                      type="date"
                      value={formik.values.dataLancamento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={
                        formik.touched.dataLancamento &&
                        Boolean(formik.errors.dataLancamento)
                      }
                      helperText={
                        formik.touched.dataLancamento &&
                        formik.errors.dataLancamento
                      }
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddFilial(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                      sx={{
                        border: formik.errors?.filial ? "1px solid red" : "",
                        paddingBlock: 0,
                        height: "fit-content",
                      }}
                    >
                      <b>Filial: </b>
                      {childData.filial?.descricao}
                    </Alert>

                    <TextField
                      id="dataDocumento"
                      name="dataDocumento"
                      label="Data do Documento"
                      type="date"
                      value={formik.values.dataDocumento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={loading}
                      error={
                        formik.touched.dataDocumento &&
                        Boolean(formik.errors.dataDocumento)
                      }
                      helperText={
                        formik.touched.dataDocumento &&
                        formik.errors.dataDocumento
                      }
                      sx={{
                        width: "100%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextField
                      id="dataEntrega"
                      name="dataEntrega"
                      label="Data de Entrega"
                      value={formik.values.dataEntrega}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.dataEntrega &&
                        Boolean(formik.errors.dataEntrega)
                      }
                      helperText={
                        formik.touched.dataEntrega && formik.errors.dataEntrega
                      }
                      type="date"
                      sx={{
                        width: "100%",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <Alert
                      icon={false}
                      severity="success"
                      action={
                        <MDButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          iconOnly
                          onClick={() => setShowAddFuncionario(true)}
                        >
                          <Icon fontSize="small">edit</Icon>
                        </MDButton>
                      }
                      sx={{
                        border: formik.errors.user ? "1px solid red" : "",
                        paddingBlock: 0,
                        height: "fit-content",
                      }}
                    >
                      <b>Funcionário comprador: </b>
                      {childData.user?.name || childData.user?.nome}
                    </Alert>

                    <TextField
                      id="status"
                      name="status"
                      label="Status"
                      value="A"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      helperText={formik.touched.status && formik.errors.status}
                      sx={{
                        width: "100%",
                      }}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {Object.values(STATUS_PEDIDO).map((opcao) => (
                        <option key={opcao} value={opcao}>
                          {findStatusPedido(opcao)}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      label="Valor total"
                      value={new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: formik?.values?.currency || "BRL",
                      }).format(commaToDot(formik.values.valorTotal))}
                      sx={{
                        width: "100%",
                        gridColumn: "2",
                      }}
                      disabled
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} sx={{ mx: "auto" }} mb={2}>
                    <Tabs
                      orientation={tabsOrientation}
                      value={tabValue}
                      onChange={handleSetTabValue}
                      className={classes.tabs}
                    >
                      <Tab label="Itens do Pedido" icon={<InventoryIcon />} />
                      <Tab
                        label="Contas à Pagar do Pedido"
                        icon={<LocalAtmIcon />}
                      />
                    </Tabs>

                    {tabValue === 0 && (
                      <Itens
                        {...{
                          formik,
                          loading,
                        }}
                        setShowAddProduto={setShowAddProduto}
                        setCurrentItem={setCurrentItem}
                        currentItem={currentItem}
                        isAdd={true}
                        fetchData={fetchData}
                      />
                    )}
                    {tabValue === 1 && (
                      <Contas
                        {...{ formik, loading }}
                        cliente={formik.values.supplier}
                        valorTotal={formik.values.valorTotal}
                        maxValor={formik.values.valorTotal}
                      />
                    )}
                  </Grid>
                  <Button
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {formik.errors.submit && (
                  <Box>
                    <MDAlert color="error" mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        {formik.errors.submit}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}

                {erroProduto && (
                  <Box>
                    <MDAlert color="error" mt={4} dismissible key={alertKey}>
                      <MDTypography variant="body2" color="white">
                        Você precisa adicionar um produto para cadastrar o
                        pedido de compra.
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Pedido de compra cadastrado com sucesso!"
                          : "Ocorreu um erro ao cadastrar o pedido de compra."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        {/* forms alternativos */}
        {showAddProduto && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarProduto
              setShowAddProduto={setShowAddProduto}
              selectParent={setCurrentItem}
              disableForm={true}
              fromParent={true}
              grupoProduto="ALMOXARIFADO"
            />
          </MDBox>
        )}

        {showAddFornecedor && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFornecedor
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, supplier: value })
                );
                selectParent("supplier", value?._id);
              }}
              setShowAddFornecedor={setShowAddFornecedor}
            />
          </MDBox>
        )}

        {showAddFuncionario && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFuncionario
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, user: value })
                );
                selectParent("user", value?._id);
              }}
              setShowAddFuncionario={setShowAddFuncionario}
            />
          </MDBox>
        )}

        {showAddFilial && (
          <MDBox
            pt={"30px"}
            pb={"5%"}
            px={"5%"}
            sx={{
              position: "absolute",
              zIndex: 9999,
              width: "100vw",
              minHeight: "100vh",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              backdropFilter: "blur(5px)",
              top: 0,
              right: 0,
              overflow: "auto",
            }}
          >
            <AdicionarFilial
              fromParent={true}
              selectParent={(value) => {
                setChildData((prevState) =>
                  Object({ ...prevState, filial: value })
                );
                selectParent("filial", value?._id);
              }}
              setShowAddCentroCusto={setShowAddFilial}
            />
          </MDBox>
        )}
      </MDBox>
    </>
  );
}
