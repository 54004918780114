import { Box, Button, Card, Grid, Icon, TextField } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import AccountGroupsService from "services/account-groups";
import AccountService from "services/accounts";
import BatchOfSettlementsService from "services/batchOfSettlements";
import Service from "services/payables";
import PaymentMethodService from "services/payment-methods";
import AccountSubgroupService from "services/sub-group-accounts";
import SupplierService from "services/suppliers";
import { format } from "utils/formatDate";

import CurrencyInput from "react-currency-input-field";
import {
  BILLING_STATUS,
  findBillingStatus,
  initialValues,
  yupSchema,
} from "./yupSchema";

export default function EditarContaAPagar({
  setShowEditState,
  currentIdAction,
  fetchData,
  fecharOnSave = false,
  dataPedido = null,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [alertKey, setAlertKey] = useState(0);
  const [dataList, setDataList] = useState({
    accountSubgroup: [],
    accountGroups: [],
    paymentMethods: [],
    suppliers: [],
    accounts: [],
  });
  const [isStatusPago, setIsStatusPago] = useState(false);
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: accountGroups } = await AccountGroupsService.list();
        const { data: accountSubgroup } = await AccountSubgroupService.list();
        const { data: paymentMethods } = await PaymentMethodService.list();
        const { data: suppliers } = await SupplierService.list();
        const { data: accounts } = await AccountService.list();

        setDataList({
          accountSubgroup,
          accountGroups,
          paymentMethods,
          suppliers,
          accounts,
        });
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yupSchema,
    onSubmit: async (values) => {
      setLoading(true);

      if (
        formik.values.dataPagamento?.toString().length > 0 &&
        (formik.values.account?.toString().length === 0 ||
          formik.values.account === null)
      ) {
        alert("Favor informar a conta!");
        setLoading(false);
        return;
      }

      try {
        // If status is "Pago", only allow updating the observacoes field
        if (isStatusPago) {
          const updatedValues = { observacoes: values.observacoes };
          await Service.update(currentIdAction, updatedValues);
        } else {
          await Service.update(currentIdAction, values);
        }

        if (fecharOnSave) {
          fetchData();
          setShowEditState(false);
        } else {
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
        setSuccess(false);
      }
      setAlertKey(alertKey + 1);
      setLoading(false);
    },
  });

  useEffect(() => {
    const fetchList = async () => {
      setLoading(true);
      const fields = await Service.get(currentIdAction);
      const batch = await BatchOfSettlementsService.getByPayable(
        currentIdAction
      );
      setBatches(batch);
      const valorPago = batch.reduce((batchAcc, currentBatch) => {
        return (
          batchAcc +
          currentBatch.payables
            .filter((item) => item.payable_id === currentIdAction)
            .reduce((acc, item) => acc + item.valor, 0)
        );
      }, 0);
      formik.setFieldValue("valorAberto", valorPago - fields.valor);
      for (var key in formik.values) {
        if (key.includes("data"))
          formik.setFieldValue(
            key,
            fields[key] !== undefined && fields[key] !== null
              ? format(new Date(fields[key]), "yyyy-MM-dd")
              : ""
          );
        else if (
          key === "accountGroup" ||
          key === "accountSubGroup" ||
          key === "account" ||
          key === "paymentMethod" ||
          key === "supplier"
        ) {
          if (fields[key] !== undefined && fields[key] !== null) {
            formik.setFieldValue(key, fields[key]._id || fields[key]);
          }
        } else formik.setFieldValue(key, fields[key]);
      }

      if (fields.status === BILLING_STATUS.PAGO) {
        setIsStatusPago(true);
      }

      setLoading(false);
    };

    fetchList();
  }, [currentIdAction]);

  const changeCurrency = (value, name) => {
    formik.setFieldValue(name, value);
  };

  const addZero = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      formik.setFieldValue(name, "0,00");
    } else {
      const removeRS = value.replace("R$", "").trim();
      const removeDots = removeRS.replace(/\./g, "");

      if (value.indexOf(",") === -1) {
        formik.setFieldValue(name, `${removeDots},00`);
      }
    }
  };

  useEffect(() => {
    const closeOnEscapePressed = (e) => {
      if (e.key === "Escape") {
        fetchData();
        setShowEditState(false);
        setSuccess(null);
      }
    };
    window.addEventListener("keydown", closeOnEscapePressed);
    return () => window.removeEventListener("keydown", closeOnEscapePressed);
  }, []);

  useEffect(() => {
    if (dataPedido && formik.values.dataVencimento) {
      const dataPedidoFormatada = new Date(dataPedido);
      const dataVencimento = new Date(formik.values.dataVencimento);

      if (dataVencimento < dataPedidoFormatada) {
        formik.setFieldValue("dataVencimento", null);
        alert(
          "A data do vencimento indicada é inferior a data do pedido, por favor, realize a correção."
        );
      }
    }
  }, [formik.values.dataVencimento]);

  return (
    <>
      <MDBox
        pt={"30px"}
        sx={{
          position: "absolute",
          zIndex: 9999,
          width: "100vw",
          minHeight: "100vh",
          top: 0,
          right: 0,
        }}
      >
        <MDBox
          pt={"30px"}
          sx={{
            position: "fixed",
            zIndex: -1,
            width: "100vw",
            height: "110%",
            backgroundColor: "rgba(0,0,0,0.5)",
            backdropFilter: "blur(5px)",
            top: 0,
            right: 0,
          }}
        />
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={8} mx={"auto"}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white" mt={1}>
                  Editar Conta a pagar
                </MDTypography>
                <MDButton
                  variant="contained"
                  type="button"
                  onClick={() => {
                    fetchData();
                    setSuccess(null);
                    setShowEditState(false);
                  }}
                >
                  Voltar
                </MDButton>
              </MDBox>
              <MDBox pt={3} mx={2} py={3} px={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    mb: 2,
                  }}
                  component="form"
                  onSubmit={formik.handleSubmit}
                >
                  <TextField
                    id="partidaContabil"
                    name="partidaContabil"
                    label="Partida Contábil"
                    type="number"
                    value={formik.values.partidaContabil}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.partidaContabil &&
                      Boolean(formik.errors.partidaContabil)
                    }
                    helperText={
                      formik.touched.partidaContabil &&
                      formik.errors.partidaContabil
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    disabled={isStatusPago}
                  />

                  <TextField
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.descricao &&
                      Boolean(formik.errors.descricao)
                    }
                    helperText={
                      formik.touched.descricao && formik.errors.descricao
                    }
                    disabled={isStatusPago}
                  />

                  <TextField
                    id="supplier"
                    name="supplier"
                    label="Fornecedor"
                    value={formik.values.supplier}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.supplier && Boolean(formik.errors.supplier)
                    }
                    helperText={
                      formik.touched.supplier && formik.errors.supplier
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStatusPago}
                  >
                    <option value="" />
                    {dataList.suppliers
                      .filter((option) => option.ativo == true)
                      .map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.razaoSocial || option.nomeFantasia}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Método de Pagamento"
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paymentMethod &&
                      Boolean(formik.errors.paymentMethod)
                    }
                    helperText={
                      formik.touched.paymentMethod &&
                      formik.errors.paymentMethod
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStatusPago}
                  >
                    <option value="" />
                    {dataList.paymentMethods.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    id="dataPedido"
                    name="dataPedido"
                    label="Data do Pedido"
                    type="date"
                    value={formik.values.dataPedido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataPedido &&
                      Boolean(formik.errors.dataPedido)
                    }
                    helperText={
                      formik.touched.dataPedido && formik.errors.dataPedido
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStatusPago}
                  />

                  <TextField
                    id="dataVencimento"
                    name="dataVencimento"
                    label="Data de Vencimento"
                    type="date"
                    value={formik.values.dataVencimento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataVencimento &&
                      Boolean(formik.errors.dataVencimento)
                    }
                    helperText={
                      formik.touched.dataVencimento &&
                      formik.errors.dataVencimento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStatusPago}
                  />

                  <TextField
                    id="dataPagamento"
                    name="dataPagamento"
                    label="Data de Pagamento"
                    type="date"
                    value={formik.values.dataPagamento}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dataPagamento &&
                      Boolean(formik.errors.dataPagamento)
                    }
                    helperText={
                      formik.touched.dataPagamento &&
                      formik.errors.dataPagamento
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isStatusPago}
                  />

                  <CurrencyInput
                    id="valor"
                    name="valor"
                    label="Valor do Título"
                    value={formik.values.valor}
                    onBlur={formik.handleBlur}
                    disabled={loading || isStatusPago}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    sx={{
                      width: "100%",
                    }}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                  />

                  <CurrencyInput
                    id="valorAberto"
                    name="valorAberto"
                    label="Valor em Aberto"
                    value={formik.values.valorAberto}
                    onBlur={formik.handleBlur}
                    disabled={loading}
                    decimalsLimit={2}
                    onValueChange={changeCurrency}
                    onBlurCapture={addZero}
                    customInput={TextField}
                    error={
                      formik.touched.valorAberto &&
                      Boolean(formik.errors.valorAberto)
                    }
                    helperText={
                      formik.touched.valorAberto && formik.errors.valorAberto
                    }
                    sx={{
                      width: "100%",
                    }}
                    prefix="R$ "
                    intlConfig={{ locale: "pt-BR", currency: "BRL" }}
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                  />

                  <TextField
                    id="status"
                    name="status"
                    label="Status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    disabled={isStatusPago}
                  >
                    <option value=""></option>
                    {Object.values(BILLING_STATUS).map((status) => (
                      <option key={status} value={status}>
                        {findBillingStatus(status)}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="account"
                    name="account"
                    label="Conta"
                    value={formik.values.account}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.account && Boolean(formik.errors.account)
                    }
                    helperText={formik.touched.account && formik.errors.account}
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={
                      formik.values.account?.length > 0
                        ? {
                            shrink: true,
                          }
                        : {}
                    }
                    disabled={isStatusPago}
                  >
                    <option value="" />
                    {dataList.accounts.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountGroup"
                    name="accountGroup"
                    label="Centro de Custo"
                    value={formik.values.accountGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountGroup &&
                      Boolean(formik.errors.accountGroup)
                    }
                    helperText={
                      formik.touched.accountGroup && formik.errors.accountGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={
                      formik.values.accountGroup?.length > 0
                        ? {
                            shrink: true,
                          }
                        : {}
                    }
                    disabled={isStatusPago}
                  >
                    <option value="" />
                    {dataList.accountGroups.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.descricao}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="accountSubGroup"
                    name="accountSubGroup"
                    label="Natureza de Lançamento"
                    value={formik.values.accountSubGroup}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.accountSubGroup &&
                      Boolean(formik.errors.accountSubGroup)
                    }
                    helperText={
                      formik.touched.accountSubGroup &&
                      formik.errors.accountSubGroup
                    }
                    select
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={
                      formik.values.accountSubGroup?.length > 0
                        ? {
                            shrink: true,
                          }
                        : {}
                    }
                    disabled={isStatusPago}
                  >
                    <option value="" />
                    {dataList.accountSubgroup.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.nome}
                      </option>
                    ))}
                  </TextField>

                  <TextField
                    id="observacoes"
                    name="observacoes"
                    label="Observações"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.observacoes &&
                      Boolean(formik.errors.observacoes)
                    }
                    helperText={
                      formik.touched.observacoes && formik.errors.observacoes
                    }
                    multiline
                    rows={4}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                    }}
                  />

                  {batches.length > 0 && (
                    <MDBox
                      sx={{
                        gridColumn: "span 2",
                        justifySelf: "center",
                        width: "100%",
                      }}
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        iconOnly
                        sx={{
                          width: "100%",
                          color: "white !important",
                          cursor: "pointer",
                        }}
                        readOnly
                      >
                        <Icon fontSize="small" sx={{ mr: 1 }}>
                          inbox
                        </Icon>
                        Lotes de baixa em que é mencionado
                      </MDButton>

                      {batches.map((batch) => (
                        <table
                          style={{
                            width: "100%",
                            borderCollapse: "collapse",
                            marginTop: "10px",
                            backgroundColor: "#fff",
                            boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                          }}
                        >
                          <thead>
                            <tr
                              style={{
                                backgroundColor: "#f5f5f5",
                                borderBottom: "2px solid #ddd",
                              }}
                            >
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Lote
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Descrição
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Conta de Origem
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "left" }}
                              >
                                Data do Pagamento
                              </th>
                              <th
                                style={{ padding: "12px", textAlign: "right" }}
                              >
                                Valor da Baixa
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style={{ borderBottom: "1px solid #ddd" }}>
                              <td style={{ padding: "12px" }}>{batch.nome}</td>
                              <td style={{ padding: "12px" }}>
                                {batch.descricao}
                              </td>
                              <td style={{ padding: "12px" }}>
                                {batch.account.nome}
                              </td>
                              <td style={{ padding: "12px" }}>
                                {(batch.payables.find(
                                  (item) => item.payable_id === currentIdAction
                                )?.dataBaixa ||
                                  batch.dataPagamento) &&
                                  new Intl.DateTimeFormat("pt-BR").format(
                                    new Date(
                                      batch.payables.find(
                                        (item) =>
                                          item.payable_id === currentIdAction
                                      )?.dataBaixa || batch.dataPagamento
                                    )
                                  )}
                              </td>
                              <td
                                style={{ padding: "12px", textAlign: "right" }}
                              >
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(
                                  batch.payables.find(
                                    (item) =>
                                      item.payable_id === currentIdAction
                                  )?.valor || 0
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </MDBox>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      gridColumn: "span 2",
                      justifySelf: "center",
                      width: "100%",
                      color: "white !important",
                    }}
                  >
                    {loading ? "Carregando..." : "Salvar"}
                  </Button>
                </Box>

                {success !== null && (
                  <Box>
                    <MDAlert
                      color={success ? "success" : "error"}
                      mt={4}
                      dismissible
                      key={alertKey}
                    >
                      <MDTypography variant="body2" color="white">
                        {success
                          ? "Conta a pagar atualizada com sucesso!"
                          : "Ocorreu um erro ao atualizar a conta a pagar."}
                      </MDTypography>
                    </MDAlert>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
